import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, ValidationErrors} from '@angular/forms';

@Component({
  selector: 'app-form-error-inspect',
  templateUrl: './form-error-inspect.component.html',
  styleUrls: ['./form-error-inspect.component.scss']
})
export class FormErrorInspectComponent implements OnInit {

  @Input()
  public formGroup: FormGroup;

  constructor() { }

  ngOnInit(): void {
  }

  getFormValidationErrors(): void {
    Object.keys(this.formGroup.controls).forEach(key => {

      const controlErrors: ValidationErrors = this.formGroup.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }

}
