<h1 mat-dialog-title>Dettaglio</h1>
<mat-table mat-dialog-content
           class="main-table"
           [dataSource]="data.data | keyvalue">
  <ng-container matColumnDef="key">
    <mat-cell *matCellDef="let data"><strong>{{data.key}}</strong></mat-cell>
  </ng-container>
  <ng-container matColumnDef="value">
    <mat-cell *matCellDef="let data">
      <app-operazione-dettaglio-data-value [value]="data.value"></app-operazione-dettaglio-data-value>
    </mat-cell>
  </ng-container>

  <mat-row *matRowDef="let data; columns: ['key', 'value'];"></mat-row>
</mat-table>
