export interface TipoUtente {
  id: number;
  gestione: string;
  descrizione: string;
  cliente: number;
  clienti: number;
  filiale: number;
}

export function tipoUtenteIdentifier(tipoUtente: TipoUtente): number {
  return tipoUtente.id;
}

export function tipoUtenteStringifier(tipoUtente: TipoUtente): string {
  return tipoUtente.descrizione;
}
