import {Component, OnInit} from '@angular/core';
import {OperazioniUtenteService} from '../../../services/operazioni-utente.service';
import {System} from '../../../models/entities/system';
import {SystemsService} from '../../../services/systems.service';

@Component({
  selector: 'app-operazioni',
  templateUrl: './operazioni.component.html',
  styleUrls: ['./operazioni.component.scss']
})
export class OperazioniComponent implements OnInit {
  public systemUrl: string = '';

  constructor(public operazioniService: OperazioniUtenteService,
              public _systemsService: SystemsService) {
  }

  ngOnInit(): void {
    switch (this._systemsService.selectedSystem) {
      case System.SDOC:
        this.systemUrl = 'sdoc';
        break;
      case System.RACC:
        this.systemUrl = 'raccomandate';
        break;
      case System.PARCEL:
        this.systemUrl = 'parcel';
        break;
      case System.ATTI:
        this.systemUrl = 'atti';
        break;
    }
  }




}
