import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import 'hammerjs';
import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule} from '@fuse/components';
import {fuseConfig} from 'app/fuse-config';
import {AppComponent} from 'app/app.component';
import {LayoutModule} from 'app/layout/layout.module';
import {CommonModule} from './fulmine/common/common.module';
import {CommonComponentsModule} from './fulmine/common/common-components.module';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {BaseComponent} from './fulmine/common/components/pages/base/base.component';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTableModule} from '@angular/material/table';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {RouterModule} from '@angular/router';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSortModule} from '@angular/material/sort';
import {InputDialogComponent} from './fulmine/common/components/dialogs/input-dialog/input-dialog.component';
import {FilialeAndDataSelectorDialogComponent} from './fulmine/common/components/dialogs/filiale-and-data-selector-dialog/filiale-and-data-selector-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {DropdownDialogComponent} from './fulmine/common/components/dialogs/dropdown-dialog/dropdown-dialog.component';
import {AngularCropperjsModule} from 'angular-cropperjs';
import {NgxMatDatetimePickerModule} from "@angular-material-components/datetime-picker";


@NgModule({
  declarations: [
    AppComponent,
    BaseComponent,
    InputDialogComponent,
    FilialeAndDataSelectorDialogComponent,
    DropdownDialogComponent,
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        CommonModule,
        CommonComponentsModule,
        MatInputModule,
        MatDatepickerModule,
        MatSelectModule,
        MatCheckboxModule,
        MatTableModule,
        MatProgressBarModule,
        RouterModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatSortModule,
        MatDialogModule,

        // Cropper JS
        AngularCropperjsModule,
        NgxMatDatetimePickerModule
    ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
