import {Injectable} from '@angular/core';
import {Paginator} from '../../models/paginator';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Operazione} from '../../models/entities/operazione';

@Injectable({
  providedIn: 'root'
})
export class OperazioniUtentePaginatorService extends Paginator<Operazione> {

  constructor(http: HttpClient) {
    super(http, `${environment.baseUrl}api/operazioni/utente`);
  }
}
