import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './guards/auth.guard';
import {LoginComponent} from './components/pages/login/login.component';
import {SystemsComponent} from './components/pages/systems/systems.component';
import {SplashComponent} from './components/pages/splash/splash.component';
import {OperazioneComponent} from './components/pages/operazione/operazione.component';
import {CambioPasswordComponent} from './components/pages/cambio-password/cambio-password.component';
import {OperazioniComponent} from './components/pages/operazioni/operazioni.component';

const routes: Routes = [
  {path: 'cambio_password', component: CambioPasswordComponent, canActivate: [AuthGuard]},
  {path: 'operazioni', component: OperazioniComponent, canActivate: [AuthGuard]},
  {path: 'operazioni/:id', component: OperazioneComponent, canActivate: [AuthGuard]},
  {path: 'login', component: LoginComponent},
  {path: 'splash', component: SplashComponent},
  {path: 'systems', component: SystemsComponent, canActivate: [AuthGuard]},

  {
    path: 'atti',
    loadChildren: async () => await (import('../atti/atti.module').then(m => m.AttiModule)),
    canActivate: [AuthGuard]
  },
  {
    path: 'parcel',
    loadChildren: async () => await (import('../parcel/parcel.module').then(m => m.ParcelModule)),
    canActivate: [AuthGuard]
  },
  {
    path: 'raccomandate',
    loadChildren: async () => await (import('../raccomandate/raccomandate.module').then(m => m.RaccomandateModule)),
    canActivate: [AuthGuard]
  },
  {
    path: 'sdoc',
    loadChildren: async () => await (import('../sdoc/sdoc.module').then(m => m.SdocModule)),
    canActivate: [AuthGuard]
  },

  {path: '**', redirectTo: 'systems'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class RoutingModule {
}
