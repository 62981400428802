import {Injectable} from '@angular/core';
import {Paginator} from '../../models/paginator';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Operazione} from '../../models/entities/operazione';

@Injectable({
  providedIn: 'root'
})
export class OperazioniPaginatorService extends Paginator<Operazione> {
  public includes = ['user'];

  constructor(http: HttpClient) {
    super(http, `${environment.baseUrl}api/operazioni`);
  }
}
