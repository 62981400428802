import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss']
})
export class InputDialogComponent implements OnInit {
  title: string;
  required = false;
  label: string;
  formControl = new FormControl('');

  constructor(
    private _dialogRef: MatDialogRef<InputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Payload) {

    if (data) {
      this.title = data.title;
      this.required = data.required;
      this.label = data.label;
      if (this.required) {
        this.formControl = new FormControl('', Validators.required);
      }
    }
  }

  public onConfirmButtonClicked(): void {
    if (this.formControl.valid) {
      this._dialogRef.close(this.formControl.value);
    } else {
      this.formControl.markAllAsTouched();
    }
  }

  ngOnInit(): void {
  }

}

export interface Payload {
  title: string;
  label: string;
  required: boolean;
}
