<h1 mat-dialog-title>{{title}}</h1>
<mat-dialog-content>
  <mat-form-field appearance="outline">
    <mat-label>{{label}}</mat-label>
    <input matInput [required]="required" [formControl]="formControl">
    <mat-error>Questo campo è obbligatorio.</mat-error>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions  fxLayout="row" fxLayoutAlign="end" fxLayoutGap="16px">
  <button mat-flat-button mat-dialog-close>Annulla</button>
  <button mat-raised-button (click)="onConfirmButtonClicked()" color="accent" [disabled]="!formControl.value">Conferma</button>
</mat-dialog-actions>
