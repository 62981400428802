import {FuseNavigation} from '@fuse/types';

export const navigation: FuseNavigation[] = [
  {
    id: 'home',
    title: 'Home',
    type: 'group',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        icon: 'dashboard',
        url: '/parcel/dashboard',
      }
    ]
  },
  {
    id: 'registries',
    title: 'Anagrafiche',
    type: 'group',
    children: [
      {
        id: 'users',
        title: 'Utenti',
        type: 'item',
        icon: 'person',
        url: '/users',
      },
      {
        id: 'filiali',
        title: 'Filiali',
        type: 'collapsable',
        icon: 'business',
        children: [
          {
            id: 'anagrafiche',
            title: 'Anagrafiche',
            type: 'item',
            url: '/filiali',
          },
          {
            id: 'tariffe filiali',
            title: 'Tariffe filiali',
            type: 'item',
            url: 'test'
          },
          {
            id: 'tariffe extra filiali',
            title: 'Tariffe extra filiali',
            type: 'item',
            url: 'test'
          },
          {
            id: 'tariffe resi mittente filiali',
            title: 'Tariffe resi mittente filiali',
            type: 'item',
            url: 'test'
          },
          {
            id: 'tariffe importazioni manuali filiali',
            title: 'Tariffe importazioni manuali filiali',
            type: 'item',
            url: 'test'
          },
          {
            id: 'royalty capoarea',
            title: 'Royalty capoarea',
            type: 'item',
            url: 'test'
          },
          {
            id: 'costi extra filiale',
            title: 'Costi extra filiale',
            type: 'item',
            url: 'test'
          },
          {
            id: 'giorni alert',
            title: 'Giorni alert',
            type: 'item',
            url: 'test'
          },
          {
            id: 'portalettere',
            title: 'Portalettere',
            type: 'item',
            url: 'test'
          },
        ]
      },
      {
        id: 'clienti',
        title: 'Clienti',
        type: 'collapsable',
        icon: 'business_center',
        children: [
          {
            id: 'anagrafiche',
            title: 'Anagrafiche',
            type: 'item',
            url: '/clienti'
          },
          {
            id: 'tariffe cliente',
            title: 'Tariffe cliente',
            type: 'item',
            url: 'test'
          },
          {
            id: 'tariffe extra cliente',
            title: 'Tariffe extra cliente',
            type: 'item',
            url: 'test'
          },
          {
            id: 'tariffe servizi cliente',
            title: 'Tariffe servizi cliente',
            type: 'item',
            url: 'test'
          },
          {
            id: 'tariffe resi mittente cliente',
            title: 'Tariffe resi mittente cliente',
            type: 'item',
            url: 'test'
          },
          {
            id: 'tariffe importazioni manuali cliente',
            title: 'Tariffe importazioni manuali cliente',
            type: 'item',
            url: 'test'
          },
          {
            id: 'giacenze personalizzate cliente',
            title: 'Giacenze personalizzate cliente',
            type: 'item',
            url: 'test'
          },
        ]
      },
      {
        id: 'zone disagiate',
        title: 'Zone disagiate',
        type: 'item',
        icon: 'location_off',
        url: 'test'
      },
      {
        id: 'terzi operatori',
        title: 'Terzi operatori',
        type: 'item',
        icon: 'person',
        url: 'test'
      },
      {
        id: 'opzioni notifica',
        title: 'Opzioni notifica',
        type: 'item',
        icon: 'settings',
        url: 'test'
      },
      {
        id: 'credenziali ftp',
        title: 'Credenziali ftp',
        type: 'item',
        icon: 'vpn_lock',
        url: 'test'
      },
      {
        id: 'copertura cap smistamento',
        title: 'Copertura cap smistamento',
        type: 'item',
        icon: 'device_hub',
        url: 'test'
      },
      {
        id: 'configurazioni regionali',
        title: 'Configurazioni regionali',
        type: 'item',
        icon: 'settings',
        url: 'test'
      },
    ]
  },
  {
    id: 'gestione',
    title: 'Gestione',
    type: 'group',
    children: [
      {
        id: 'lotti (importazione/esportazione)',
        title: 'Lotti (importazione/esportazione)',
        type: 'item',
        icon: 'widgets',
        url: 'test'
      },
      {
        id: 'dispacci (trasferimenti)',
        title: 'Dispacci (trasferimenti)',
        type: 'item',
        icon: 'widgets',
        url: 'test'
      },
      {
        id: 'affidi portalettere',
        title: 'Affidi portalettere',
        type: 'item',
        icon: 'add_to_home_screen',
        url: 'test'
      },
      {
        id: 'cambio indirizzo pacchetto',
        title: 'Cambio indirizzo pacchetto',
        type: 'item',
        icon: 'place',
        url: 'test'
      },
      {
        id: 'acquisizione dati filiale',
        title: 'Acquisizione dati filiale',
        type: 'item',
        icon: 'cloud_download',
        url: 'test'
      },
    ]
  },
  {
    id: 'accettazione',
    title: 'Accettazione Fil/Hub',
    type: 'group',
    children: [
      {
        id: 'accettazione',
        title: 'Accettazione',
        type: 'item',
        icon: 'check',
        url: 'test'
      },
      {
        id: 'accettazioni sospese',
        title: 'Accettazioni sospese',
        type: 'item',
        icon: 'timer_off',
        url: 'test'
      },
    ]
  },
  {
    id: 'Anomalie',
    title: 'Anomalie',
    type: 'group',
    children: [
      {
        id: 'tracciatura anomalie',
        title: 'Tracciatura anomalie',
        type: 'item',
        icon: 'my_location',
        url: 'test'
      },
      {
        id: 'scarto su richiesta cliente',
        title: 'Scarto su richiesta cliente',
        type: 'item',
        icon: 'cancel',
        url: 'test'
      },
    ]
  },
  {
    id: 'esiti',
    title: 'Esiti',
    type: 'group',
    children: [
      {
        id: 'conferma consegna',
        title: 'Conferma consegna',
        type: 'item',
        icon: 'assignment_turned_in',
        url: 'test'
      },
      {
        id: 'tracciatura resi',
        title: 'Tracciatura resi',
        type: 'item',
        icon: 'my_location',
        url: 'test'
      },
      {
        id: 'tracciatura fermo concordato',
        title: 'Tracciatura fermo concordato',
        type: 'item',
        icon: 'my_location',
        url: 'test'
      },
      {
        id: 'consegna in ufficio',
        title: 'Consegna in ufficio',
        type: 'item',
        icon: 'assignment_turned_in',
        url: 'test'
      },
      {
        id: 'tracciatura compiute giacenze',
        title: 'Tracciatura compiute giacenze',
        type: 'item',
        icon: 'my_location',
        url: 'test'
      },
      {
        id: 'tracciatura resi mittente',
        title: 'Tracciatura resi mittente',
        type: 'item',
        icon: 'my_location',
        url: 'test'
      },
      {
        id: 'tracciatura smarrimento resi',
        title: 'Tracciatura smarrimento resi',
        type: 'item',
        icon: 'my_location',
        url: 'test'
      },
      {
        id: 'ritorno lettere di vettura al mittente',
        title: 'Ritorno lettere di vettura al mittente',
        type: 'item',
        icon: 'assignment_return',
        url: 'test'
      },
      {
        id: 'upload dati acquisire',
        title: 'Upload dati acquisire',
        type: 'item',
        icon: 'cloud_upload',
        url: 'test'
      },
    ]
  },
  {
    id: 'appuntamenti',
    title: 'Appuntamenti affido a terzi',
    type: 'group',
    children: [
      {
        id: 'affido a terzo operatore',
        title: 'Affido a terzo operatore',
        type: 'item',
        icon: 'add_to_home_screen',
        url: 'test'
      },
      {
        id: 'affido multiplo a terzo operatore',
        title: 'Affido multiplo a terzo operatore',
        type: 'item',
        icon: 'add_to_home_screen',
        url: 'test'
      },
      {
        id: 'tracciatura esiti terzi operatori',
        title: 'Tracciatura esiti terzi operatori',
        type: 'item',
        icon: 'my_location',
        url: 'test'
      },
    ]
  },
  {
    id: 'report',
    title: 'Report',
    type: 'group',
    children: [
      {
        id: 'report lotti',
        title: 'Report lotti',
        type: 'item',
        icon: 'bar_chart',
        url: 'test'
      },
      {
        id: 'lasciati avvisi',
        title: 'Lasciati avvisi',
        type: 'item',
        icon: 'bar_chart',
        url: 'test'
      },
      {
        id: 'resi mittente',
        title: 'Resi mittente',
        type: 'item',
        icon: 'bar_chart',
        url: 'test'
      },
    ]
  }
];
