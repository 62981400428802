import { Component, OnDestroy, OnInit } from '@angular/core';
import { UsersPaginatorService } from '../../../services/paginators/users-paginator.service';
import { FuseProgressBarService } from '../../../../../../@fuse/components/progress-bar/progress-bar.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseSidebarService } from '../../../../../../@fuse/components/sidebar/sidebar.service';
import { ColumnFilter, ColumnFilterType, RelationFilter } from '../../partials/filters/filters.component';
import { Query } from '../../../models/paginator';
import { SystemsService } from '../../../services/systems.service';
import { System } from '../../../models/entities/system';
import { TipiUtentePaginatorService } from '../../../services/paginators/tipi-utente-paginator.service';
import { HttpClient } from '@angular/common/http';
import { tipoUtenteIdentifier, tipoUtenteStringifier } from '../../../models/entities/tipo-utente';
import { ClientiPaginatorService } from '../../../services/paginators/clienti-paginator.service';
import { clienteIdentifier, clienteStringifier } from '../../../models/entities/cliente';
import { FilialiPaginatorService } from '../../../services/paginators/filiali-paginator.service';
import { filialeIdentifier, filialeStringifier } from '../../../models/entities/filiale';
import { UserService } from 'app/fulmine/common/services/user.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {
  private _queries = new BehaviorSubject<Query[]>(null);
  private systemQuery: Query;
  private _unsubscribeAll: Subject<any>;

  public displayedColumns: string[];
  public filters: (ColumnFilter | RelationFilter<any>)[];

  constructor(private _fuseProgressBarService: FuseProgressBarService,
    private _fuseSidebarService: FuseSidebarService,
    private _http: HttpClient,
    private _systemsService: SystemsService,
    public usersPaginator: UsersPaginatorService,
    private userService: UserService,) { }

  ngOnInit(): void {
    this._unsubscribeAll = new Subject();

    this.usersPaginator.isLoading.pipe(takeUntil(this._unsubscribeAll)).subscribe(isLoading => {
      if (isLoading) {
        this._fuseProgressBarService.show();
      } else {
        this._fuseProgressBarService.hide();
      }
    });

    this.systemQuery = { column: 'gestione', search: System[this._systemsService.selectedSystem] };
    this.usersPaginator.queries.next([this.systemQuery]);
    this._queries.pipe(takeUntil(this._unsubscribeAll)).subscribe(queries => {      
      this.usersPaginator.queries.next([
        ...(queries ?? []),
        this.systemQuery
      ]);
    });

    this.filters = [
      { label: 'Nome', column: 'name', type: ColumnFilterType.STRING },
      {
        label: 'Tipo',
        column: `${System[this._systemsService.selectedSystem]?.toLowerCase()}_tipo`,
        paginator: new TipiUtentePaginatorService(this._http),
        filters: [{ column: 'gestione', search: System[this._systemsService.selectedSystem] }],
        searchField: 'descrizione',
        entityIdentifier: tipoUtenteIdentifier,
        entityStringifier: tipoUtenteStringifier,
      },
      {
        label: 'Cliente',
        column: 'cli_id',
        paginator: new ClientiPaginatorService(this._http),
        searchField: 'cli_nome_rag_soc',
        entityIdentifier: clienteIdentifier,
        entityStringifier: clienteStringifier,
      },
      {
        label: 'Filiale',
        column: 'fil_id',
        paginator: new FilialiPaginatorService(this._http),
        searchField: 'fil_nome',
        entityIdentifier: filialeIdentifier,
        entityStringifier: filialeStringifier,
      },
      {
        label: 'Attivi',
        column: 'attivo',
        type: ColumnFilterType.ARRAY, values: [
          { label: 'Tutti', value: null },
          { label: 'Sì', value: true },
          { label: 'No', value: false }
        ],
        defaultValue: true
      },
      {
        label: 'Gestione',
        column: 'gestione',
        type: ColumnFilterType.ARRAY, values: [
          { label: 'SDOC', value: 'sdoc' },
          { label: 'Raccomandate', value: 'racc' },
          { label: 'Parcel', value: 'parcel' },
          { label: 'Atti Giudiziari', value: 'atti' }
        ],
        defaultValue: { column: 'gestione', search: System[this._systemsService.selectedSystem] }
      },
    ];

    switch (this._systemsService.selectedSystem) {
      case System.ATTI:
        this.displayedColumns = ['id', 'email', 'name', 'username', 'cliente', 'filiale', 'tiposdoc', 'tiporacc', 'tipoatti', 'tipoparcel', 'actions'];
        break;

      case System.PARCEL:
        this.displayedColumns = ['id', 'email', 'name', 'username', 'cliente', 'filiale', 'tiposdoc', 'tiporacc', 'tipoatti', 'tipoparcel', 'actions'];
        break;

      case System.RACC:
        this.displayedColumns = ['id', 'email', 'name', 'username', 'cliente', 'filiale', 'tiposdoc', 'tiporacc', 'tipoatti', 'tipoparcel', 'actions'];
        break;

      case System.SDOC:
        if (this.userService.hasAnyRole(['sdoc_cliente', 'sdoc_ospite_cliente', 'sdoc_cliente_multiappalto', 'sdoc_cliente_sportello', 'sdoc_cliente_operatore', 'sdoc_cliente_call_center', 'sdoc_cliente_operatore_multiappalto', 'sdoc_cliente_call_center_multiappalto'])) {
          this.displayedColumns = ['id', 'email', 'name', 'username', 'cliente', /* 'filiale', */ /* 'tiposdoc', 'tiporacc', 'tipoatti', 'tipoparcel', */ /* 'sdoc_usa_ricerche', */ 'sdoc_usa_lotti', 'sdoc_usa_affidiclienti', 'sdoc_usa_reportclienti', 'sdoc_usa_resi', /* 'sdoc_clisoloricerche' */ 'sdoc_menu_ricerche', /* 'sdoc_ricparzbarc', */ /* 'sdoc_ricfattcodut', 'sdoc_riclocalita', 'sdoc_ricstatosegn', 'sdoc_conteggidettlotto', 'sdoc_esportaresituttilotti', 'sdoc_vede_inlinea', 'sdoc_usa_tariffe', 'sdoc_vede_copertura', 'sdoc_importa', */ 'actions'];
        } else
          this.displayedColumns = ['id', 'email', 'name', 'username', 'cliente', 'filiale', 'tiposdoc', 'tiporacc', 'tipoatti', 'tipoparcel', 'sdoc_usa_ricerche', 'sdoc_usa_lotti', 'sdoc_usa_affidiclienti', 'sdoc_usa_reportclienti', 'sdoc_usa_resi', /* 'sdoc_clisoloricerche' */ 'sdoc_menu_ricerche', 'sdoc_ricparzbarc', 'sdoc_ricfattcodut', 'sdoc_riclocalita', 'sdoc_ricstatosegn', 'sdoc_conteggidettlotto', 'sdoc_esportaresituttilotti', 'sdoc_vede_inlinea', 'sdoc_usa_tariffe', 'sdoc_vede_copertura', 'sdoc_importa', 'actions'];
        break;
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  public toggleSidebar(name): void {
    this._fuseSidebarService.getSidebar(name).toggleOpen();
  }

  public onFiltersChanged(queries: Query[]): void {
    const queryGestione = queries.filter(query => (query.column === 'gestione'));
    if (queryGestione) {
      this.systemQuery = queryGestione[0];
    }
    this._queries.next(queries);
  }

  isChecked(user){
    console.log(user);
    
  }
}
