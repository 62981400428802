<mat-form-field  class="w-100-p" appearance="outline">
  <mat-label>{{label}}</mat-label>

  <mat-chip-list #chipList>
    <mat-chip *ngFor="let entity of selectedEntities; let i = index"
              [removable]="!disabled"
              (removed)="onEntityRemoved(i)">
      {{entityStringifier(entity)}}
      <mat-icon *ngIf="!disabled" matChipRemove>cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>

  <button *ngIf="!disabled" mat-icon-button matSuffix (click)="onAddButtonClicked()"><mat-icon>add</mat-icon></button>

  <input matInput readonly [matChipInputFor]="chipList" [required]="required">
</mat-form-field>
