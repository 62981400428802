import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../base/base.component";
import {UserService} from "../../../services/user.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ConfirmValidator} from "../../../models/confirm-validator";
import {ApiResponse} from "../../../models/api-response";
import {EchoService} from "ngx-laravel-echo";
import {OAuthService} from "angular-oauth2-oidc";
import {Router} from "@angular/router";
import {OperazioniUtenteService} from "../../../services/operazioni-utente.service";

@Component({
  selector: 'app-cambio-password',
  templateUrl: './cambio-password.component.html',
  styleUrls: ['./cambio-password.component.scss']
})
export class CambioPasswordComponent extends BaseComponent implements OnInit {

  public hidePassword = true;
  public hideConfirmPassword = true;
  public formGroup: FormGroup;

  constructor(public userService: UserService,
              private _echoService: EchoService,
              private _oauthService: OAuthService,
              private _router: Router) {
    super();
  }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      password: new FormControl('',
        Validators.compose([
          Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@()$%^&*=_{}[\\]:;\\"\'|\\\\<>,.\\/~`±§+-]).{8,30}$'),
          Validators.required,
        ])
      ),
      password_confirmation: new FormControl('', Validators.required)
    }, ConfirmValidator.passwordValidation);
  }

  public async onSaveButtonClicked(): Promise<ApiResponse<void>> {
    if (this.formGroup.invalid) {
      return;
    }

    this._fuseProgressBarService.show();
    let response: ApiResponse<any> = await this.userService.changePassword(this.formGroup.value);
    this._fuseProgressBarService.hide();
    if (response) {
      this._toastr.success(response.message ?? 'Operazione eseguita con successo. Rieffettuare il login', 'Successo!');
      this._oauthService.logOut();
      this._echoService.logout();
      this._router.navigate(['/login']);
    }
  }

}
