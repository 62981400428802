<h1 mat-dialog-title>{{title}}</h1>
<form [formGroup]="formGroup" fxLayout="column" fxLayout.gt-md="row" fxLayoutGap.gt-md="16px">
  <mat-form-field appearance="outline" fxFlex>
    <mat-label>Data ora</mat-label>
    <input matInput [ngxMatDatetimePicker]="dtora_picker" formControlName="dtora">
    <mat-datepicker-toggle matSuffix [for]="dtora_picker"></mat-datepicker-toggle>
    <ngx-mat-datetime-picker #dtora_picker></ngx-mat-datetime-picker>
  </mat-form-field>
</form>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end" fxLayoutGap="16px">
  <button mat-flat-button mat-dialog-close>Annulla</button>
  <button mat-raised-button color="accent" (click)="onConfirmButtonClicked()" [disabled]="!isValid">Conferma</button>
</mat-dialog-actions>
