import {Injectable} from '@angular/core';
import {Paginator} from '../../../common/models/paginator';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Appalto} from '../../models/entities/appalto';

@Injectable({
  providedIn: 'root'
})
export class AppaltiPaginatorService extends Paginator<Appalto> {

  constructor(http: HttpClient) {
    super(http, `${environment.baseUrl}api/sdoc/appalti`);
  }
}
