import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'ar'
})
export class ArPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value || value === 1 || value === '1') {
      return 'SI'
    }
    return '';
  }

}
