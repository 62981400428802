import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import {isMoment, Moment} from 'moment';

@Pipe({
  name: 'moment'
})
export class MomentPipe implements PipeTransform {

  transform(value: unknown, ...args: string[]): unknown {
    let parsed: Moment;
    if (isMoment(value)) {
      parsed = value;
    } else {
      parsed = moment(value);
    }
    if (!parsed.isValid()) {
      return '-';
    }
    parsed.locale('it-IT');
    if (args.length > 0){
      return parsed.format(args[0]);
    }
    return parsed.format('L LTS');
  }
}
