import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AppInjectorService} from '../../../services/app-injector.service';
import {FuseProgressBarService} from '../../../../../../@fuse/components/progress-bar/progress-bar.service';
import {FuseSidebarService} from '../../../../../../@fuse/components/sidebar/sidebar.service';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-base',
  template: ``,
  styles: []
})
export class BaseComponent {
  protected _dialog: MatDialog;
  protected _fuseProgressBarService: FuseProgressBarService;
  protected _fuseSidebarService: FuseSidebarService;
  protected _http: HttpClient;
  protected _toastr: ToastrService;

  constructor() {
    this._dialog = AppInjectorService.injector.get(MatDialog);
    this._fuseProgressBarService = AppInjectorService.injector.get(FuseProgressBarService);
    this._fuseSidebarService = AppInjectorService.injector.get(FuseSidebarService);
    this._http = AppInjectorService.injector.get(HttpClient);
    this._toastr = AppInjectorService.injector.get(ToastrService);
  }
}
