<div class="page-layout simple fullwidth">

  <!-- HEADER -->
  <div class="header px-64 py-24" fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="m-0">{{cliente ? cliente.cli_nome_rag_soc.toUpperCase() : 'NUOVO CLIENTE'}}</h2>

    <button mat-raised-button class="action-button" color="accent" (click)="onSaveButtonClicked()"
            [disabled]="!formGroup || formGroup.invalid || !oneGestioneSelected() || (_clientiApiService.isLoading | async)">
      <mat-icon>save</mat-icon>
      SALVA
    </button>

    <app-form-error-inspect *ngIf="debug && formGroup" [formGroup]="formGroup"></app-form-error-inspect>

  </div>

  <!-- / HEADER -->

  <!-- CONTENT -->
  <form *ngIf="formGroup" class="content px-64 py-24" fusePerfectScrollbar [formGroup]="formGroup" fxLayout="column">

    <!-- Informazioni generali -->
    <div class="fuse-card auto-width">
      <div class="p-24" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="mat-title m-0">Informazioni generali</span>

        <mat-checkbox formControlName="cli_attivo">Attivo</mat-checkbox>
      </div>

      <div class="p-24">
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field appearance="outline" fxFlex="50" class="mr-8">
            <mat-label>Nome</mat-label>
            <input required matInput formControlName="cli_nome_rag_soc" maxlength="150">
            <mat-error>Questo campo è obbligatorio.</mat-error>
            <mat-error>Lunghezza massima 150 caratteri</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="50" class="ml-8">
            <mat-label>Email</mat-label>
            <input required matInput type="email" formControlName="cli_email" maxlength="100">
            <mat-error>Email non valida</mat-error>
            <mat-error>Questo campo è obbligatorio.</mat-error>
          </mat-form-field>
        </div>

        <div class="mt-16" fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field appearance="outline" fxFlex="80" class="mr-8">
            <mat-label>Indirizzo</mat-label>
            <input required matInput formControlName="cli_indirizzo" maxlength="50">
            <mat-error>Questo campo è obbligatorio.</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="20" class="ml-8">
            <mat-label>CAP</mat-label>
            <input required matInput formControlName="cli_cap" maxlength="5">
            <mat-error>Questo campo è obbligatorio.</mat-error>
          </mat-form-field>
        </div>

        <div class="mt-16" fxLayout="row" fxLayoutAlign="space-between center">
          <mat-form-field appearance="outline" fxFlex="80" class="mr-8">
            <mat-label>Località</mat-label>
            <input required matInput formControlName="cli_localita" maxlength="30">
            <mat-error>Questo campo è obbligatorio.</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="20" class="ml-8">
            <mat-label>Provincia</mat-label>
            <input required matInput formControlName="cli_prov" maxlength="2">
            <mat-error>Questo campo è obbligatorio.</mat-error>
          </mat-form-field>
        </div>

        <div class="mt-16" fxLayout="row" fxLayoutAlign="space-between center">
          <mat-form-field appearance="outline" fxFlex="33" class="mr-16">
            <mat-label>CIG</mat-label>
            <input matInput formControlName="cli_cig" maxlength="10">
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="34" class="mr-16">
            <mat-label>Partita IVA</mat-label>
            <input matInput formControlName="cli_piva" maxlength="16">
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="33">
            <mat-label>Codice Fiscale</mat-label>
            <input matInput formControlName="cli_cf" maxlength="16">
          </mat-form-field>
        </div>

        <div class="mt-16" fxLayout="row" fxLayoutAlign="space-between center">
          <mat-form-field appearance="outline" fxFlex="33" class="mr-16">
            <mat-label>Codice cliente ARCA</mat-label>
            <input required matInput formControlName="cli_codcliarca">
            <mat-error>Questo campo è obbligatorio.</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="34" class="mr-16">
            <mat-label>Nome cliente controllo</mat-label>
            <input matInput maxlength="60" formControlName="cli_nome_controllo">
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex="33" >
            <mat-label>Cod. Agente</mat-label>
            <input matInput maxlength="20" formControlName="cli_cod_agente">
          </mat-form-field>
        </div>

        <mat-form-field class="mt-16 w-100-p" appearance="outline">
          <mat-label>Note</mat-label>
          <input matInput formControlName="cli_note">
        </mat-form-field>
      </div>
    </div>
    <!-- / Informazioni generali -->

    <!-- Opzioni -->
    <div class="fuse-card auto-width mt-32">
      <div class="p-24">
        <span class="mat-title m-0">Opzioni</span>
      </div>

      <div class="p-24" fxLayout="row wrap" fxLayoutGap="32px">
        <mat-checkbox formControlName="cli_reportcliente">Vede report cliente</mat-checkbox>

        <mat-checkbox formControlName="cli_spedisce_dt_compiuta_giac">Spedisce DT compiuta giacenza</mat-checkbox>

        <mat-checkbox formControlName="cli_spedisce_dt_in_consegna">Spedisce DT in consegna</mat-checkbox>

        <mat-checkbox formControlName="cli_inverti_cli_sottocli_su_ric">Inverti clienti e sottoclienti su ricerca
        </mat-checkbox>

        <mat-checkbox formControlName="cli_esitiposte">Esiti poste</mat-checkbox>

        <mat-checkbox formControlName="cli_ricercanota2">Ricerca Nota2 (cod. utente)</mat-checkbox>

        <mat-checkbox *ngIf="this._userService.hasAnyRole(['superadmin', 'racc_admin', 'sdoc_admin', 'sdoc_supervisore', 'racc_supervisore'])"
                      formControlName="cli_menu_utenti">Vede il menu utenti (crea utenti)</mat-checkbox>

        <mat-radio-group formControlName="cli_tipospedizione" aria-label="Tipo di spedizione">
          <mat-radio-button class="mr-8" value="cli_spedirenuovi">Spedisci solo record modificati</mat-radio-button>
          <mat-radio-button value="cli_spediretutto">Spedisci tutti i record</mat-radio-button>
        </mat-radio-group>

      </div>
    </div>
    <!-- / Opzioni -->

    <!-- SDOC -->
    <div class="fuse-card auto-width mt-32" *ngIf="_userService.user?.sdoc">
      <div class="p-24" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="mat-title m-0">Gestione SDOC</span>

        <mat-checkbox class="mt-16" formControlName="cli_sdoc">Attiva</mat-checkbox>
      </div>

      <fieldset class="p-24 no-border" [disabled]="!formGroup.value.cli_sdoc">
        <div fxLayout="row" fxLayoutAlign="start">
          <mat-checkbox class="mr-16 mt-20" formControlName="cli_sdoc_sportello" fxFlex>Sportello</mat-checkbox>

          <mat-form-field class="mx-16" appearance="outline" fxFlex>
            <mat-label>Codice sportello</mat-label>
            <input matInput formControlName="cli_sdoc_sportello_cod">
          </mat-form-field>

        </div>

        <fieldset class="p-16" [disabled]="formGroup.value.cli_sdoc_sportello">
          <div class="mt-16" fxLayout="row" fxLayoutAlign="start">
            <mat-checkbox class="mr-16 mt-20" formControlName="cli_sdoc_controllo">Monitoraggio controllo</mat-checkbox>

            <mat-form-field class="mx-16" appearance="outline" fxFlex="30">
              <mat-label>AppCod</mat-label>
              <input matInput [required]="!!formGroup.value.cli_sdoc && !formGroup.value.cli_sdoc_sportello" formControlName="cli_appcod_sdoc">
            </mat-form-field>

            <mat-form-field class="mx-16" appearance="outline" fxFlex="30">
              <mat-label>Ente</mat-label>
              <input matInput [required]="!!formGroup.value.cli_sdoc && !formGroup.value.cli_sdoc_sportello" formControlName="cli_ente_sdoc" maxlength="20">
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="70">
              <mat-label>Giorni controllo</mat-label>
              <input matInput type="number" formControlName="cli_sdoc_gg_controllo">
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field appearance="outline" fxFlex="33">
              <mat-label>Giorni SLA AM</mat-label>
              <input matInput type="number" formControlName="cli_sdoc_sla_am">
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="34" class="mx-16">
              <mat-label>Giorni SLA CP</mat-label>
              <input matInput type="number" formControlName="cli_sdoc_sla_cp">
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="33">
              <mat-label>Giorni SLA EU</mat-label>
              <input matInput type="number" formControlName="cli_sdoc_sla_eu">
            </mat-form-field>
          </div>

          <div class="mt-16" fxLayout="row wrap" fxLayoutGap="32px">
            <mat-checkbox formControlName="cli_sdoc_vede_linea">Vede linea</mat-checkbox>

            <mat-checkbox formControlName="cli_sdoc_spedizione">Spedizione attiva</mat-checkbox>

            <mat-checkbox formControlName="cli_sdoc_tracciato_resi_dettagliato">Tracciato resi dettaglio</mat-checkbox>
          </div>
        </fieldset>
      </fieldset>
    </div>
    <!-- / SDOC -->

    <!-- Parcel -->
    <div class="fuse-card auto-width mt-32" *ngIf="_userService.user?.parcel">
      <div class="p-24" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="mat-title m-0">Gestione Parcel</span>

        <mat-checkbox class="mt-16" formControlName="cli_parcel">Attiva</mat-checkbox>
      </div>

      <fieldset class="p-24 no-border" [disabled]="!formGroup.value.cli_parcel">
        <div fxLayout="row" fxLayoutAlign="start">
          <mat-checkbox class="mr-16 mt-20" formControlName="cli_parcel_riceveesitiftp">Riceve esiti FTP</mat-checkbox>

          <mat-form-field class="mr-8" appearance="outline" fxFlex>
            <mat-label>Frequenza invio FTP</mat-label>
            <input matInput type="number" formControlName="cli_parcel_frequenzainvioesitiftp" min="0" max="255">
          </mat-form-field>

          <mat-form-field class="ml-8" appearance="outline" fxFlex>
            <mat-label>Giorni alert pacco non pervenuto</mat-label>
            <input matInput type="number" formControlName="cli_parcel_ggalertpaccononpervenuto" min="0" max="255">
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start">
          <mat-form-field class="mr-8" appearance="outline" fxFlex>
            <mat-label>Giorni SLA recapito</mat-label>
            <input matInput type="number" formControlName="cli_parcel_ggslarecapito">
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Modo Fatturazione</mat-label>
            <mat-select formControlName="cli_parcel_modo_fatturazione" class="ml-8">
              <mat-option value="A">Accettato in un arco temporale</mat-option>
              <mat-option value="T">Affidato in un arco temporale</mat-option>
            </mat-select>
          </mat-form-field>

        </div>

        <div class="mt-16" fxLayout="row wrap" fxLayoutGap="32px">
          <mat-checkbox formControlName="cli_parcel_obbligodispacci">Obbligo dispacci</mat-checkbox>
        </div>
      </fieldset>
    </div>
    <!-- / Parcel -->

    <!-- Raccomandate -->
    <div class="fuse-card auto-width mt-32" *ngIf="_userService.user?.racc">
      <div class="p-24" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="mat-title m-0">Gestione Raccomandate</span>

        <mat-checkbox class="mt-16" formControlName="cli_racc">Attiva</mat-checkbox>
      </div>

      <fieldset class="p-24 no-border" [disabled]="!formGroup.value.cli_racc">

        <div fxLayout="row" fxLayoutAlign="start">
          <mat-checkbox class="mr-16 mt-20" formControlName="cli_racc_riceveesitiftp">Riceve esiti FTP</mat-checkbox>

          <mat-form-field fxFlex appearance="outline">
            <mat-label>Frequenza invio FTP</mat-label>
            <mat-select formControlName="cli_racc_frequenzainvioesitiftp">
              <mat-option [value]="1">Quotidianamente</mat-option>
              <mat-option [value]="2">Settimanalmente</mat-option>
              <mat-option [value]="3">Mensilmente</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="ml-8" appearance="outline" fxFlex>
            <mat-label>Limite Giorni invio esiti via FTP:</mat-label>
            <input matInput type="number" formControlName="cli_racc_giornispedizioneftp" min="0" max="255">
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutGap="32px">
          <mat-form-field appearance="outline">
            <mat-label>Modo Fatturazione</mat-label>
            <mat-select formControlName="cli_racc_modo_fatturazione" class="ml-8" [required]="!!formGroup.value.cli_racc">
              <mat-option value="A">Accettato in un arco temporale</mat-option>
              <mat-option value="T">Affidato in un arco temporale</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-checkbox class="ml-16 mr-16 mt-20" formControlName="cli_racc_non_considerare_padre_su_ricevute">Non
            considerare padre su ricevute
          </mat-checkbox>

          <mat-checkbox class="ml-16 mr-16 mt-20" formControlName="cli_racc_firmaelettronica">Abilita Firma
            Elettronica
          </mat-checkbox>
        </div>
        <div fxLayout="row wrap" fxLayoutGap="32px">
          <mat-checkbox class="ml-16 mr-16 mt-20" formControlName="cli_racc_spedisciricevute">Allega ricevute in spedizione</mat-checkbox>

          <mat-checkbox class="ml-16 mr-16 mt-20" formControlName="cli_racc_spediscicontracciatoeglue">Spedisce gli esiti usando il tracciato Eglue</mat-checkbox>

          <mat-checkbox class="ml-16 mr-16 mt-20" formControlName="cli_racc_spediscicontracciatoenel">Spedisce gli esiti usando il tracciato Enel</mat-checkbox>

          <mat-checkbox class="ml-16 mr-16 mt-20" *ngIf="this._userService.hasAnyRole(['superadmin', 'racc_admin', 'sdoc_admin', 'sdoc_supervisore', 'racc_supervisore'])"
                        formControlName="cli_racc_menu_lotti">Vede il menu lotti</mat-checkbox>
        </div>
      </fieldset>
    </div>
    <!-- / Raccomandate -->

    <!-- Altre gestioni -->
    <div class="fuse-card auto-width mt-32" *ngIf="_userService.user?.atti">
      <div class="p-24">
        <span class="mat-title m-0">Altre gestioni</span>
      </div>

      <div class="p-24" fxLayout="row wrap" fxLayoutGap="32px">
        <mat-checkbox formControlName="cli_atti">Atti Giudiziari</mat-checkbox>
      </div>
    </div>
    <!-- / Altre gestioni -->

    <!-- Informazioni aggiuntive -->
    <div class="fuse-card auto-width mt-32">
      <div class="p-24">
        <span class="mat-title m-0">Informazioni aggiuntive</span>
      </div>

      <div class="p-24">
        <app-entity-selector label="Filiale Royalty"
                             searchField="fil_nome"
                             [paginator]="filialePaginator"
                             [filters]="[{column: 'capoarea_o_filmadre', search: 1}]"
                             [disabled]="_userService.hasAnyRole(['sdoc_filiale', 'racc_filiale', 'parcel_filiale', 'atti_filiale'])"
                             [value]="filialeRoyalty"
                             [entityStringifier]="filialeStringifier"
                             (valueChanges)="filialeRoyalty = $event"></app-entity-selector>

        <app-entity-selector label="Cliente padre"
                             searchField="cli_nome_rag_soc"
                             [paginator]="padrePaginator"
                             [filters]="filtriClientePadre()"
                             [value]="padre"
                             [required]="formGroup.value.cli_sdoc_sportello == 1"
                             [entityStringifier]="clienteStringifier"
                             (valueChanges)="onClientePadreCambiato($event)"></app-entity-selector>

        <app-multi-entity-selector class="mt-16"
                                   label="Clienti figli"
                                   [searchFields]="['cli_nome_rag_soc']"
                                   [paginator]="figliPaginator"
                                   [filters]="[{column: 'con_padre', search: false}]"
                                   [dialogColumns]="clienteColumnDefinitions"
                                   [dialogDisplayedColumns]="clienteDefaultDisplayedColumns"
                                   [entityStringifier]="clienteStringifier"
                                   [(selectedEntities)]="figli"></app-multi-entity-selector>

      </div>
    </div>
    <!-- / Informazioni aggiuntive -->

  </form>
  <!-- / CONTENT -->

</div>
