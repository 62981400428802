import {Injectable} from '@angular/core';
import {ApiService} from '../../models/api-service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Operazione} from '../../models/entities/operazione';

@Injectable({
  providedIn: 'root'
})
export class OperazioniApiService extends ApiService<Operazione> {

  constructor(http: HttpClient) {
    super(http, `${environment.baseUrl}api/operazioni`);
  }
}
