import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FuseConfigService} from '@fuse/services/config.service';
import {fuseAnimations} from '@fuse/animations';
import {OAuthService} from 'angular-oauth2-oidc';
import {FuseProgressBarService} from '../../../../../../@fuse/components/progress-bar/progress-bar.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {FuseSplashScreenService} from '../../../../../../@fuse/services/splash-screen.service';
import {EchoService} from 'ngx-laravel-echo';
import {BehaviorSubject} from "rxjs";
import {OperazioniUtenteService} from "../../../services/operazioni-utente.service";
import {NotificationsService} from "../../../services/notifications.service";

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  hide = true;
  isLoading = new BehaviorSubject<boolean>(false);

  constructor(
    private _echoService: EchoService,
    private _fuseConfigService: FuseConfigService,
    private _fuseProgressBarService: FuseProgressBarService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _formBuilder: FormBuilder,
    private _oauthService: OAuthService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _operazioniService: OperazioniUtenteService,
    private _notificationsService: NotificationsService
  ) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };
  }

  ngOnInit(): void {
    this.loginForm = this._formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  public async onSubmit(): Promise<void> {
    this._fuseProgressBarService.show();
    this.isLoading.next(true);
    try {
      const result = await this._oauthService.fetchTokenUsingPasswordFlow(this.loginForm.value.username, this.loginForm.value.password);
      await this._userService.load();
      if (result && this._userService.user) {
        this._echoService.login({Authorization: this._oauthService.authorizationHeader()}, this._userService.user.id);
        await this._router.navigate([
          this._route.snapshot.queryParamMap.has('redirectTo') ? this._route.snapshot.queryParamMap.get('redirectTo') : 'systems'
        ]);
      }
    } catch (exception) {
      console.log(exception);
      this.isLoading.next(false);
    } finally {
      this._fuseProgressBarService.hide();
      this._operazioniService.login();
      this._notificationsService.login();
      this.isLoading.next(false);
    }
  }
}
