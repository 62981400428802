import {Component, OnDestroy, OnInit} from '@angular/core';
import {ClientiPaginatorService} from '../../../services/paginators/clienti-paginator.service';
import {FuseSidebarService} from '../../../../../../@fuse/components/sidebar/sidebar.service';
import {ColumnFilter, ColumnFilterType, RelationFilter} from '../../partials/filters/filters.component';
import {FuseProgressBarService} from '../../../../../../@fuse/components/progress-bar/progress-bar.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Query} from '../../../models/paginator';
import {SystemsService} from '../../../services/systems.service';
import {System} from '../../../models/entities/system';

@Component({
  selector: 'app-clienti',
  templateUrl: './clienti.component.html',
  styleUrls: ['./clienti.component.scss']
})
export class ClientiComponent implements OnInit, OnDestroy {
  private _queries = new BehaviorSubject<Query[]>(null);
  private systemQuery: Query;
  private _unsubscribeAll: Subject<any>;

  public displayedColumns: string[];
  public filters: (ColumnFilter | RelationFilter<any>)[];

  constructor(private _fuseProgressBarService: FuseProgressBarService,
              private _fuseSidebarService: FuseSidebarService,
              private _systemsService: SystemsService,
              public clientiPaginator: ClientiPaginatorService) {
  }

  ngOnInit(): void {
    this._unsubscribeAll = new Subject();

    this.clientiPaginator.isLoading.pipe(takeUntil(this._unsubscribeAll)).subscribe(isLoading => {
      if (isLoading) {
        this._fuseProgressBarService.show();
      } else {
        this._fuseProgressBarService.hide();
      }
    });

    this.filters = [
      {label: 'Nome', column: 'cli_nome_rag_soc', type: ColumnFilterType.STRING},
      {
        label: 'Tipologia',
        column: 'cli_sdoc_sportello',
        type: ColumnFilterType.ARRAY, values: [
          {label: 'Tutti', value: null},
          {label: 'Clienti sportello', value: true},
          {label: 'Clienti non sportello', value: false}
        ]
      },
      {
        label: 'Attivi',
        column: 'cli_attivo',
        type: ColumnFilterType.ARRAY,
        values: [
          {label: 'Tutti', value: null},
          {label: 'Sì', value: true},
          {label: 'No', value: false}
        ],
        defaultValue: true
      },
      {
        label: 'Gestione',
        column: 'gestione',
        type: ColumnFilterType.ARRAY,
        values: [
          {label: 'SDOC', value: 'sdoc'},
          {label: 'Raccomandate', value: 'racc'},
          {label: 'Parcel', value: 'parcel'},
          {label: 'Atti Giudiziari', value: 'atti'}
        ],
        defaultValue: {column: 'gestione', search: System[this._systemsService.selectedSystem]}
      },
    ];

    this.systemQuery = {column: 'gestione', search: System[this._systemsService.selectedSystem]};
    this.clientiPaginator.queries.next([this.systemQuery]);
    this._queries.pipe(takeUntil(this._unsubscribeAll)).subscribe(queries => {
      this.clientiPaginator.queries.next([
        ...(queries ?? []),
        this.systemQuery
      ]);
    });

    switch (this._systemsService.selectedSystem) {
      case System.ATTI:
        // tslint:disable-next-line:max-line-length
        this.displayedColumns = ['cli_id', 'cli_nome_rag_soc', 'padre', 'figli', 'cli_amministratore', 'cli_indirizzo', 'cli_cap', 'cli_localita', 'cli_prov', 'cli_piva', 'cli_cf', 'cli_note', 'cli_reportcliente', 'cli_codcliarca', 'cli_attivo', 'cli_spedirenuovi', 'cli_spediretutto', 'cli_ricercanota2', 'cli_spedisce_dt_compiuta_giac', 'cli_spedisce_dt_in_consegna', 'cli_esitiposte', 'cli_nome_controllo', 'cli_cod_agente', 'updated_at', 'actions'];
        break;

      case System.PARCEL:
        // tslint:disable-next-line:max-line-length
        this.displayedColumns = ['cli_id', 'cli_nome_rag_soc', 'padre', 'figli', 'cli_amministratore', 'cli_indirizzo', 'cli_cap', 'cli_localita', 'cli_prov', 'cli_piva', 'cli_cf', 'cli_note', 'cli_reportcliente', 'cli_codcliarca', 'cli_attivo', 'cli_spedirenuovi', 'cli_spediretutto', 'cli_ricercanota2', 'cli_spedisce_dt_compiuta_giac', 'cli_spedisce_dt_in_consegna', 'cli_esitiposte', 'cli_parcel_obbligodispacci', 'cli_parcel_riceveesitiftp', 'cli_modofatturazione', 'cli_nome_controllo', 'cli_cod_agente', 'updated_at', 'actions'];
        break;

      case System.RACC:
        // tslint:disable-next-line:max-line-length
        this.displayedColumns = ['cli_id', 'cli_nome_rag_soc', 'padre', 'figli', 'cli_amministratore', 'cli_indirizzo', 'cli_cap', 'cli_localita', 'cli_prov', 'cli_piva', 'cli_cf', 'cli_note', 'cli_reportcliente', 'cli_codcliarca', 'cli_attivo', 'cli_spedirenuovi', 'cli_spediretutto', 'cli_ricercanota2', 'cli_spedisce_dt_compiuta_giac', 'cli_spedisce_dt_in_consegna', 'cli_esitiposte', 'cli_modofatturazione', 'cli_nome_controllo', 'cli_cod_agente', 'updated_at', 'actions'];
        break;

      case System.SDOC:
        // tslint:disable-next-line:max-line-length
        this.displayedColumns = ['cli_id', 'cli_appcod_sdoc', 'cli_nome_rag_soc', 'cli_ente_sdoc', 'padre', 'figli', 'cli_indirizzo', 'cli_cap', 'cli_localita', 'cli_prov', 'cli_piva', 'cli_cf', 'cli_sdoc_controllo', 'cli_sdoc_spedizione', 'cli_sdoc_sportello', 'cli_sdoc_sportello_cod', 'cli_nome_controllo', 'cli_cod_agente' , 'updated_at', 'actions'];
        break;
    }
  }

  public modoFatturazione(modoFatturazione: string): string {
    switch (modoFatturazione) {
      case 'A':
        return 'Accettato in un arco temporale';
      case 'T':
        return 'Affidato in un arco temporale';
      default:
        return '';
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  public toggleSidebar(name): void {
    this._fuseSidebarService.getSidebar(name).toggleOpen();
  }

  public onFiltersChanged(queries: Query[]): void {
    const queryGestione = queries.filter(query => (query.column === 'gestione'));
    if (queryGestione) {
      this.systemQuery = queryGestione[0];
    }
    this._queries.next(queries);
  }
}
