<div id="login" fxLayout="column">

  <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="space-between center">

    <div id="login-form"
         [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}"
         fxLayout="column"
         fxLayoutAlign="space-between center">
      <div></div>

      <div>
        <div class="logo">
          <img src="../../../../../../assets/fulmine/common/logo.png">
        </div>

        <form name="loginForm" [formGroup]="loginForm" (submit)="onSubmit()" novalidate>
          <mat-form-field appearance="outline">
            <mat-label>Username</mat-label>
            <input matInput formControlName="username">
            <mat-icon matSuffix color="accent">account_box</mat-icon>
            <mat-error *ngIf="loginForm.get('username').hasError('required')">
              Questo campo è obbligatorio
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input [type]="hide ? 'password' : 'text'" matInput formControlName="password">
            <mat-icon style="cursor: pointer" matSuffix color="accent"
                      (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error>
              Questo campo è obbligatorio
            </mat-error>
          </mat-form-field>

          <button mat-raised-button color="accent" class="submit-button"
                  [disabled]="loginForm.invalid || (isLoading | async)">
            ACCEDI
            <mat-icon>arrow_forward</mat-icon>
          </button>
        </form>
      </div>

      <span class="mat-caption">@FulmineGroup</span>

    </div>

  </div>

  <div id="bottom-text"
       fxLayout="row"
       fxHide.lt-sm>
    <mat-icon>location_on</mat-icon>

    <div fxLayout="column">
      <span class="mat-display-2"><strong>PALERMO</strong></span>
      <span class="mat-display-1">Dove tutto ha inizio…</span>
    </div>
  </div>

</div>
