<div id="systems" class="page-layout simple fullwidth" fxLayout="column" fxLayoutAlign="space-between center">

  <!-- HEADER -->
  <div class="p-24 mt-32" fxLayout="column" fxLayoutAlign="center center">
    <span class="mat-title m-0">Benvenuto {{userService.user?.name}}!</span>
    <span class="mat-display-1 m-0 common-accent-foreground">Seleziona il sistema a cui desideri accedere.</span>
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div class="content p-24 mt-116" fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="center center" fxLayoutGap.gt-md="16px" fxLayoutGap="116px">

    <a class="fuse-card sdoc-accent-foreground" href="#" routerLink="/sdoc/dashboard" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">
      <!-- (click)="goToSdocDashboard()" -->
      <div class="system-image sdoc-accent-background" fxLayout="column" fxLayoutAlign="center center">
        <img src="assets/fulmine/sdoc/logo.svg">
      </div>

      <div class="system-title p-24">
        <span class="mat-title">SISTEMA DOC</span>
      </div>

      <div class="system-content mat-body-strong p-24 pt-0 line-height-1.75">
        Ideale per aziende, professionisti, piccole e medie imprese, pubblica amministrazione, il servizio DOC (Data Ora Certa) assicura la certificazione puntuale su luogo, data e ora del recapito. Perfetto in particolare per fatture, rendiconti, comunicazioni e convocazioni importanti.
      </div>

      <mat-icon class="system-button">arrow_forward_ios</mat-icon>
    </a>

    <a class="fuse-card raccomandate-accent-foreground" href="#" routerLink="/raccomandate/dashboard" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">
      <!--  (click)="goToRaccDashboard()"  -->
      <div class="system-image raccomandate-accent-background" fxLayout="column" fxLayoutAlign="center center">
        <img src="assets/fulmine/raccomandate/logo.svg">
      </div>

      <div class="system-title p-24">
        <span class="mat-title">RACCOMANDATE</span>
      </div>

      <div class="system-content mat-body-strong p-24 pt-0 line-height-1.75">
        Il servizio perfetto per spedire documenti con valore legale.
        La Raccomandata Plus permette di visualizzare online la ricevuta di consegna firmata dal destinatario e certifica data, ora e luogo.
      </div>

      <mat-icon class="system-button">arrow_forward_ios</mat-icon>
    </a>

    <a class="fuse-card parcel-accent-foreground" target="_blank" href="https://parcel.fulminegroup.eu/" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">
      <div class="system-image parcel-accent-background" fxLayout="column" fxLayoutAlign="center center">
        <img src="assets/fulmine/parcel/logo.svg">
      </div>

      <div class="system-title p-24">
        <span class="mat-title">PARCEL</span>
      </div>

      <div class="system-content mat-body-strong p-24 pt-0 line-height-1.75">
        La divisione strategica Parcel è l’innovativa soluzione italiana che consente di inviare pacchetti risparmiando sui costi di spedizione, garantendo puntualità nel recapito e accurata assistenza. Rispondendo ad esigenze diversificate, il servizio offre la personalizzazione della consegna sulle esigenze dei clienti e permette, essendo integrato nella piattaforma, attraverso un semplice click, di monitorare la traccia della spedizione.
      </div>

      <mat-icon class="system-button">arrow_forward_ios</mat-icon>
    </a>

    <a class="fuse-card atti-accent-foreground" target="_blank" href="https://attigiudiziari.fulminegroup.eu/" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">
      <div class="system-image atti-accent-background" fxLayout="column" fxLayoutAlign="center center">
        <img src="assets/fulmine/atti/logo.svg">
      </div>

      <div class="system-title p-24">
        <span class="mat-title">ATTI GIUDIZIARI</span>
      </div>

      <div class="system-content mat-body-strong p-24 pt-0 line-height-1.75">
        Notificazione a mezzo Posta di atti giudiziari ex L. 890/1982 e ss.mm.ii così come liberalizzato con delibera AGCOM 77/18/CONS
      </div>

      <mat-icon class="system-button">arrow_forward_ios</mat-icon>
    </a>

  </div>
  <!-- / CONTENT -->

  <span class="mat-caption m-32">@FulmineGroup</span>

</div>
