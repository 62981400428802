import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SelectionModel} from '@angular/cdk/collections';
import {FormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {debounceTime, filter, takeUntil} from 'rxjs/operators';

import {Sort} from '@angular/material/sort';
import {FilialiPaginatorService} from '../../../services/paginators/filiali-paginator.service';
import {RegioniPaginatorService} from '../../../services/paginators/regioni-paginator.service';
import {
  Filiale,
  filialeConCapoareaDisplayedColumns,
  filialeColumnDefinitions,
  filialeDefaultDisplayedColumns,
  filialeStringifier
} from '../../../models/entities/filiale';
import {Regione} from '../../../models/entities/regione';
import {Types} from '../../../models/entities/system';
import {Query} from '../../../models/paginator';
import { UserService } from 'app/fulmine/common/services/user.service';

@Component({
  selector: 'app-multi-filiale-selector-dialog',
  templateUrl: './multi-filiale-selector-dialog.component.html',
  styleUrls: ['./multi-filiale-selector-dialog.component.scss']
})

export class MultiFilialeSelectorDialogComponent<T> implements OnInit, OnDestroy {

  private _unsubscribeAll: Subject<any>;

  public regioneSelected = new FormControl({value: null, disabled: true});
  public nomeFiliale = new FormControl();
  public capoareaSelected = new FormControl({value: null, disabled: true});

  public madreH = new FormControl(false);
  public madreC = new FormControl(false);
  public madreD = new FormControl(false);

  public selection: SelectionModel<Filiale> = new SelectionModel<Filiale>(true, []);
  public capoareaList: Filiale[] = null;
  public regioniList: Regione[] = null;
  public statoSelezionaTutti = false;
  public filialiFiltrate: Filiale[] = [];

  filialeColumnDefinitions = filialeColumnDefinitions;
  filialeDefaultDisplayedColumns = filialeConCapoareaDisplayedColumns;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Payload<T>,
    private _dialogRef: MatDialogRef<MultiFilialeSelectorDialogComponent<T>>,
    public filialiPaginator: FilialiPaginatorService,
    private regioniPaginator: RegioniPaginatorService,
    private userService: UserService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this._unsubscribeAll = new Subject();

    this.selection = new SelectionModel<Filiale>(true, this.data.selectedEntities ?? []);

    this.capoareaList = await this.filialiPaginator.loadAll([{
      column: 'madri',
      search: 1
    }, {
      column: 'gestione',
      search: this.data.gestione
    }], {active: 'fil_nome', direction: 'asc'});
    this.capoareaSelected.enable();
    if (this.data.regione !== undefined) {
      this.regioneSelected.setValue(this.data.regione.id, {emitEvent: false});
    }
    this.regioniList = await this.regioniPaginator.loadAll([], {active: 'nome', direction: 'asc'});
    this.regioneSelected.enable();

    if (this.data.filters?.length > 0) {
      this.filialiPaginator.queries.next(this.data.filters);
    }

    this.filialiPaginator.page.pipe(takeUntil(this._unsubscribeAll)).subscribe(page => {
      for (const element of page?.data ?? []) {
        for (const selectedElement of this.selection.selected) {
          if (JSON.stringify(element) === JSON.stringify(selectedElement)) {
            this.selection.deselect(selectedElement);
            this.selection.select(element);
            break;
          }
        }
      }
    });

    this.filtra();
  }

  public log(testo): void {
    console.log(testo);
  }

  public async filtra(nomeFiliale?: string): Promise<void> {

    const filtri: Query[] = [...(this.data.filters ?? []), ...(this.buildQueries(nomeFiliale ?? this.nomeFiliale.value))];
    this.filialiFiltrate = await this.filialiPaginator.loadAll(filtri, this.data.sort);
    console.log('filiali filtrate', this.filialiFiltrate);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private buildQueries(nomeFiliale?: string): Query[] {
    const queries = [];
    if (this.userService.user.filiale) queries.push({column: 'madre', search: this.userService.user.filiale.fil_id});
    if (nomeFiliale) {
      queries.push({column: 'fil_nome', search: nomeFiliale});
    }
    if (this.capoareaSelected.value) {
      queries.push({
        column: 'madre_e_figlie',
        search: this.capoareaSelected.value + ',' + this.data.gestione + ',' + this.madreH.value + ',' + this.madreC.value + ',' + this.madreD.value
      });
    }

    if (this.regioneSelected.value) {
      queries.push({column: 'reg_id', search: this.regioneSelected.value});
    }
    if (this.data.filters?.length > 0) {
      this.data.filters.forEach(el => {
        if (el.column !== undefined) {
          queries.push(el);
        }
      });
    }
    return queries;
  }

  public onConfirmButtonClicked(): void {
    this._dialogRef.close(this.selection.selected);
  }

  public selezionaDeselezionaTutti(): void {
    this.statoSelezionaTutti = !this.statoSelezionaTutti;
    if (this.statoSelezionaTutti) {
      this.selection.select(...this.filialiFiltrate);
    } else {
      this.selection.clear();
    }
  }

}

export interface Payload<T> {
  filters?: Query[];
  gestione?: Types;
  sort?: Sort;
  selectedEntities?: Filiale[];
  regione?: Regione;
}
