import {Component, OnInit} from '@angular/core';
import {Portalettere} from '../../../models/entities/portalettere';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {PortalettereApiService} from '../../../services/api/portalettere-api.service';
import {Filiale, filialeStringifier} from '../../../models/entities/filiale';
import {FilialiPaginatorService} from '../../../services/paginators/filiali-paginator.service';
import {ApiResponse} from '../../../models/api-response';
import * as moment from 'moment';
import {Corso, corsoStringifier} from '../../../models/entities/corso';
import {CorsoPaginatorService} from '../../../services/paginators/corso-paginator.service';
import {BaseComponent} from '../base/base.component';
import {ConditionalValidator} from '../../../models/conditional-validator';
import {SimpleAlertDialogComponent} from '../../dialogs/simple-alert-dialog/simple-alert-dialog.component';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-terminale-postino',
  templateUrl: './portalettere.component.html',
  styleUrls: ['./portalettere.component.scss']
})
export class PortalettereComponent extends BaseComponent implements OnInit {
  public formGroup: FormGroup;
  public filiale: Filiale;
  public filialePaginator: FilialiPaginatorService;
  public corso: Corso;
  public corsoPaginator: CorsoPaginatorService;
  public portalettere: Portalettere;

  public filialeStringifier = filialeStringifier;
  public corsoStringifier = corsoStringifier;

  public hidePin = true;
  public hidePassword = true;

  constructor(private _formBuilder: FormBuilder,
              private _location: Location,
              private _route: ActivatedRoute,
              public _portalettereApiService: PortalettereApiService,
              public _userService: UserService
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this._fuseProgressBarService.show();
    this.filialePaginator = new FilialiPaginatorService(this._http);
    this.corsoPaginator = new CorsoPaginatorService(this._http);
    if (this._route.snapshot.paramMap.has('id')) {
      this.portalettere = await this._portalettereApiService.get(parseInt(this._route.snapshot.paramMap.get('id'), 10));
    }
    if (this._userService.hasRole('racc_filiale')) {
      const response = await this.filialePaginator.loadPage(
        0,
        [{column: 'fil_raccid', search: this._userService.user?.filiale.fil_raccid}],
        null
      );
      this.filiale = response.data[0];
    } else {
      this.filiale = this.portalettere?.filiale ?? null;
    }
    this.corso = this.portalettere?.corso;

    this.formGroup = this._formBuilder.group({
      port_racc_numterm: [this.portalettere?.port_racc_numterm ?? null, [Validators.maxLength(3)]],
      port_parcel_numterm: [this.portalettere?.port_parcel_numterm ?? null, [Validators.maxLength(3)]],
      port_atti_numterm: [this.portalettere?.port_atti_numterm ?? null, [Validators.maxLength(3)]],
      port_cognome: [this.portalettere?.port_cognome ?? '', [Validators.maxLength(40), Validators.required]],
      port_nominativo: [this.portalettere?.port_nominativo ?? '', [Validators.maxLength(40), Validators.required]],
      port_dt_acquisto: [moment(this.portalettere?.port_dt_acquisto).isValid() ? moment(this.portalettere?.port_dt_acquisto) : moment()],
      port_dt_assunzione: [moment(this.portalettere?.port_dt_assunzione).isValid() ? moment(this.portalettere?.port_dt_assunzione) : moment()],
      port_tipo_contratto: [this.portalettere?.port_tipo_contratto ?? null, [Validators.maxLength(20)]],
      port_indirizzo: [this.portalettere?.port_indirizzo ?? null, [Validators.maxLength(50)]],
      port_localita: [this.portalettere?.port_localita ?? null, [Validators.maxLength(40)]],
      port_cap: [this.portalettere?.port_cap ?? null, [Validators.maxLength(5)]],
      port_provincia: [this.portalettere?.port_provincia ?? null, [Validators.maxLength(2)]],
      port_telefono: [this.portalettere?.port_telefono ?? null, [Validators.maxLength(25)]],
      port_note: [this.portalettere?.port_note ?? null, [Validators.maxLength(255)]],
      port_attivo: [this.portalettere?.port_attivo ?? true, [Validators.required]],
      port_disp_android_id: [this.portalettere?.port_disp_android_id ?? null],
      port_sdoc: [this.portalettere?.port_sdoc ?? false],
      port_racc: [this.portalettere?.port_racc ?? false],
      port_parcel: [this.portalettere?.port_parcel ?? false],
      port_atti: [this.portalettere?.port_atti ?? false],
      port_fittizio: [this.portalettere?.port_fittizio ?? false],
      port_sdoc_cod: [this.portalettere?.port_sdoc_cod ?? '', [Validators.maxLength(5), ConditionalValidator.predicate(() => this.formGroup.value.port_sdoc, Validators.required)]],
      // Firma Elett
      port_racc_firmaelettronica: [this.portalettere?.port_racc_firmaelettronica ?? false],
      port_consuff: [this.portalettere?.port_consuff ?? false,
        [ConditionalValidator.predicate(() => this.formGroup?.value.port_racc_firmaelettronica, Validators.required)]],
      port_pinsbloccocertificato: [null,
        [Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$'),
          ConditionalValidator.predicate(() => this.formGroup?.value.port_racc_firmaelettronica && !this.portalettere?.port_pinsbloccocertificato, Validators.required)
        ]],
      port_stato_nascita: [this.portalettere?.port_stato_nascita ?? null,
        [ConditionalValidator.predicate(() => this.formGroup?.value.port_racc_firmaelettronica, Validators.required), Validators.maxLength(2)]],
      port_cod_fiscale: [
        this.portalettere?.port_codice_fiscale ?? null, [
          // tslint:disable-next-line:max-line-length
          Validators.pattern('^([A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1})$|([0-9]{11})$'),
          ConditionalValidator.predicate(() => this.formGroup?.value.port_racc_firmaelettronica && !this.portalettere?.port_codice_fiscale, Validators.required)]
      ],
      port_email: [this.portalettere?.user?.email ?? null,
        [Validators.email,
          ConditionalValidator.predicate(() => this.formGroup?.value.port_attivo, Validators.required)]],
      port_username: [this.portalettere?.user?.username ?? null,
        [Validators.maxLength(20),
          ConditionalValidator.predicate(() => this.formGroup?.value.port_attivo, Validators.required)
        ]],
      port_password: [null,
        [Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@()$%^&*=_{}[\\]:;\\"\'|\\\\<>,.\\/~`±§+-]).{8,30}$'),
          ConditionalValidator.predicate(() => !this.portalettere?.user && this.formGroup?.value.port_attivo, Validators.required)]
      ],
      port_statocertificato: [this.portalettere?.port_statocertificato ?? 'Non Attivo']
    });
    this._fuseProgressBarService.hide();
  }

  public async onSaveButtonClicked(): Promise<void> {
    if (this.formGroup.invalid || !this.filiale) {
      return;
    }

    if (this.formGroup.value.port_racc_firmaelettronica && this.formGroup.value.port_statocertificato === 'Non Attivo') {
      const result = await this._dialog.open(SimpleAlertDialogComponent, {
        data: {
          title: 'Attenzione!',
          content: 'Verrà attivato anche un nuovo certificato per il portalettere con i dati indicati, ' +
            'NON sarà possibile modificare il PIN di sblocco certificato e il CODICE FISCALE se procedi'
        }
      }).afterClosed().toPromise();
      if (!result) {
        return;
      }
    }

    if ((!this.formGroup.value.port_racc_firmaelettronica || !this.formGroup.value.port_racc) && this.formGroup.value.port_statocertificato === 'Attivo') {
      const result = await this._dialog.open(SimpleAlertDialogComponent, {
        data: {
          title: 'Attenzione!',
          content: 'Verrà disattivato anche il certificato del portalettere collegato, per riattivarlo è sufficiente riabilitare il portalettere alla firma elettronica'
        }
      }).afterClosed().toPromise();
      if (!result) {
        return;
      }
    }

    const body = this.formGroup.value;
    body.port_filid = this.filiale.fil_id;
    body.port_dt_acquisto = moment(body.port_dt_assunzione).format('YYYY-MM-DD');
    body.port_dt_assunzione = moment(body.port_dt_assunzione).format('YYYY-MM-DD HH:mm:ss');
    body.port_sdoc_cod = body.port_sdoc_cod.toString();
    if (this.corso) {
      body.port_corsoid = this.corso.c_id;
    }

    this._fuseProgressBarService.show();
    let response: ApiResponse<Portalettere>;
    if (this.portalettere) {
      response = await this._portalettereApiService.update(this.portalettere.port_id, body);
    } else {
      response = await this._portalettereApiService.create(this.formGroup.value);
    }
    this._fuseProgressBarService.hide();
    if (response) {
      this._toastr.success(response.message ?? 'Operazione eseguita con successo.', 'Successo!');
      this._location.back();
    }
  }

  public async onDeleteButtonClicked(): Promise<void> {
    const result = await this._dialog.open(SimpleAlertDialogComponent, {
      data: {
        title: 'Cancella Record',
        content: 'Sei sicuro di voler cancellare il portalettere? Verrà cancellato anche l\' utente collegato, se presente',
      }
    }).afterClosed().toPromise();

    if (!result && !this.portalettere) {
      return;
    }

    this._fuseProgressBarService.show();
    let response: ApiResponse<Portalettere>;
    if (this.portalettere) {
      response = await this._portalettereApiService.delete(this.portalettere.port_id);
    }
    this._fuseProgressBarService.hide();
    if (response) {
      this._toastr.success(response.message ?? 'Operazione eseguita con successo.', 'Successo!');
      this._location.back();
    }
  }

  public oneGestioneSelected(): boolean {
    return this.formGroup.value.port_racc ||
      this.formGroup.value.port_parcel ||
      this.formGroup.value.port_sdoc ||
      this.formGroup.value.port_atti;
  }

}
