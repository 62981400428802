import {AbstractControl} from '@angular/forms';

export class ConfirmValidator {
  static passwordValidation(control: AbstractControl): any {
    const password: string = control.get('password').value;
    const confirmPassword: string = control.get('password_confirmation').value;
    if (password !== '' && (password !== confirmPassword)) {
      control.get('password_confirmation').setErrors({NoPasswordMatch: true});
    }
  }
}
