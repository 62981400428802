import {Component, OnInit} from '@angular/core';
import {
  Cliente,
  clienteColumnDefinitions,
  clienteDefaultDisplayedColumns,
  clienteStringifier
} from '../../../models/entities/cliente';
import {FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ClientiApiService} from '../../../services/api/clienti-api.service';
import {FuseProgressBarService} from '../../../../../../@fuse/components/progress-bar/progress-bar.service';
import {ConditionalValidator} from '../../../models/conditional-validator';
import {ToastrService} from 'ngx-toastr';
import {Location} from '@angular/common';
import {ClientiPaginatorService} from '../../../services/paginators/clienti-paginator.service';
import {HttpClient} from '@angular/common/http';
import {ApiResponse} from '../../../models/api-response';
import {Filiale, filialeIdentifier, filialeStringifier} from '../../../models/entities/filiale';
import {FilialiPaginatorService} from '../../../services/paginators/filiali-paginator.service';
import {UserService} from '../../../services/user.service';
import {environment} from '../../../../../../environments/environment';
import {AppaltiPaginatorService} from '../../../../sdoc/services/paginators/appalti-paginator.service';
import {Query} from '../../../models/paginator';

@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.scss']
})
export class ClienteComponent implements OnInit {
  public cliente: Cliente;
  public padre: Cliente;
  public filialeRoyalty: Filiale;
  public padrePaginator: ClientiPaginatorService;
  public figli: Cliente[] = [];
  public figliPaginator: ClientiPaginatorService;
  public formGroup: FormGroup;
  public filialePaginator: FilialiPaginatorService;

  public clienteStringifier = clienteStringifier;
  public clienteColumnDefinitions = clienteColumnDefinitions;
  public clienteDefaultDisplayedColumns = clienteDefaultDisplayedColumns;

  public filialeStringifier = filialeStringifier;
  public filialeIdentifier = filialeIdentifier;

  public debug: boolean = environment.debug;

  constructor(public _clientiApiService: ClientiApiService,
              private _formBuilder: FormBuilder,
              private _fuseProgressBarService: FuseProgressBarService,
              private _http: HttpClient,
              private _location: Location,
              private _route: ActivatedRoute,
              private _toastr: ToastrService,
              public _userService: UserService,
              public appaltiPaginator: AppaltiPaginatorService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.padrePaginator = new ClientiPaginatorService(this._http);
    this.figliPaginator = new ClientiPaginatorService(this._http);
    this.filialePaginator = new FilialiPaginatorService(this._http);

    if (this._route.snapshot.paramMap.has('id')) {
      this._fuseProgressBarService.show();
      this.cliente = await this._clientiApiService.get(parseInt(this._route.snapshot.paramMap.get('id'), 10));
      this.padre = this.cliente.padre;
      this.figli = this.cliente.figli;
      this._fuseProgressBarService.hide();
    }

    if (this._userService.hasRole('racc_filiale')) {
      const response = await this.filialePaginator.loadPage(
        0,
        [{column: 'fil_raccid', search: this._userService.user?.filiale.fil_raccid}],
        null
      );
      this.filialeRoyalty = response.data[0];
    }

    this.formGroup = this._formBuilder.group({
      cli_nome_rag_soc: [
        this.cliente?.cli_nome_rag_soc ?? '',
        [Validators.required, Validators.maxLength(150)]
      ],
      cli_indirizzo: [
        this.cliente?.cli_indirizzo ?? '',
        [Validators.required, Validators.maxLength(50)]
      ],
      cli_cap: [
        this.cliente?.cli_cap ?? '',
        [Validators.required, Validators.maxLength(5)]
      ],
      cli_localita: [
        this.cliente?.cli_localita ?? '',
        [Validators.required, Validators.maxLength(30)]
      ],
      cli_prov: [
        this.cliente?.cli_prov ?? '',
        [Validators.required, Validators.maxLength(2)]
      ],
      cli_cig: [
        this.cliente?.cli_cig ?? '',
        Validators.maxLength(10)
      ],
      cli_piva: [
        this.cliente?.cli_piva ?? '',
        Validators.maxLength(16)
      ],
      cli_cf: [
        this.cliente?.cli_cf ?? '',
        Validators.maxLength(16)
      ],
      cli_note: [
        this.cliente?.cli_note ?? '',
        Validators.maxLength(150)
      ],
      cli_email: [
        this.cliente?.cli_email ?? ''
        , [Validators.email, Validators.maxLength(100)]
      ],
      cli_codcliarca: [
        this.cliente?.cli_codcliarca ?? '',
        [Validators.required, Validators.pattern('^[C][0-9]{6}|CNONFAT$'), Validators.maxLength(7)]
      ],
      cli_nome_controllo: [
        this.cliente?.cli_nome_controllo ?? '',
        Validators.maxLength(60)
      ],
      cli_cod_agente: [
        this.cliente?.cli_cod_agente ?? '',
        Validators.maxLength(20)
      ],
      cli_reportcliente: [
        this.cliente?.cli_reportcliente ?? false,
        Validators.required
      ],
      cli_tipospedizione: [
        this.cliente?.cli_spediretutto ? 'cli_spediretutto' : 'cli_spedirenuovi',
        Validators.required
      ],
      cli_attivo: [
        this.cliente?.cli_attivo ?? true,
        Validators.required
      ],
      cli_spedisce_dt_compiuta_giac: [
        this.cliente?.cli_spedisce_dt_compiuta_giac ?? false,
        Validators.required
      ],
      cli_spedisce_dt_in_consegna: [
        this.cliente?.cli_spedisce_dt_in_consegna ?? false,
        Validators.required
      ],
      cli_inverti_cli_sottocli_su_ric: [
        this.cliente?.cli_inverti_cli_sottocli_su_ric ?? false,
        Validators.required
      ],
      cli_esitiposte: [
        this.cliente?.cli_esitiposte ?? false,
        Validators.required
      ],
      cli_ricercanota2: [
        this.cliente?.cli_ricercanota2 ?? false,
        Validators.required
      ],

      // Systems
      cli_atti: [
        this.cliente?.cli_atti ?? false,
        Validators.required
      ],
      cli_racc: [
        this.cliente?.cli_racc ?? false,
        Validators.required
      ],
      cli_sdoc: [
        this.cliente?.cli_sdoc ?? false,
        Validators.required
      ],
      cli_parcel: [
        this.cliente?.cli_parcel ?? false,
        Validators.required
      ],

      // Raccomandate
      cli_racc_non_considerare_padre_su_ricevute: [
        this.cliente?.cli_racc_non_considerare_padre_su_ricevute ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.cli_racc, Validators.required)
      ],
      cli_racc_modo_fatturazione: [
        this.cliente?.cli_racc_modo_fatturazione,
        ConditionalValidator.predicate(() => this.formGroup.value.cli_racc,
          Validators.compose([Validators.required, Validators.max(1)])
        )
      ],

      cli_racc_firmaelettronica: [
        this.cliente?.cli_racc_firmaelettronica ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.cli_racc,
          Validators.compose([Validators.required])
        )
      ],
      cli_racc_riceveesitiftp: [
        this.cliente?.cli_racc_riceveesitiftp ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.cli_racc, Validators.required)
      ],
      cli_racc_frequenzainvioesitiftp: [
        this.cliente?.cli_racc_frequenzainvioesitiftp ?? '0',
        ConditionalValidator.predicate(
          () => this.formGroup.value.cli_racc && this.formGroup.value.cli_racc_riceveesitiftp,
          Validators.required
        )
      ],
      cli_racc_giornispedizioneftp: [
        this.cliente?.cli_racc_giornispedizioneftp ?? 0,
        ConditionalValidator.predicate(
          () => this.formGroup.value.cli_racc && this.formGroup.value.cli_racc_riceveesitiftp,
          Validators.compose([Validators.required, Validators.max(255)])
        )
      ],
      cli_racc_spedisciricevute: [
        this.cliente?.cli_racc_spedisciricevute ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.cli_racc,
          Validators.compose([Validators.required])
        )
      ],
      cli_racc_spediscicontracciatoeglue: [
        {
          value: this.cliente?.cli_racc_spediscicontracciatoeglue ?? false,
          disabled: !!this.formGroup?.value.cli_racc_spediscicontracciatoenel
        },
        ConditionalValidator.predicate(() => this.formGroup.value.cli_racc,
          Validators.compose([Validators.required])
        )
      ],
      cli_racc_spediscicontracciatoenel: [
        {
          value: this.cliente?.cli_racc_spediscicontracciatoenel ?? false,
          disabled: !!this.formGroup?.value.cli_racc_spediscicontracciatoeglue
        },
        ConditionalValidator.predicate(() => this.formGroup.value.cli_racc,
          Validators.compose([Validators.required])
        )
      ],
      cli_menu_utenti : [
         this.cliente?.cli_menu_utenti ?? false,
      ],
      cli_racc_menu_lotti: [
        this.cliente?.cli_racc_menu_lotti ?? false,
      ],

      // SDOC
      cli_appcod_sdoc: [
        this.cliente?.cli_appcod_sdoc,
        Validators.compose([Validators.maxLength(3), ConditionalValidator.predicate(() => this.formGroup.value.cli_sdoc, Validators.required)])
      ],
      cli_ente_sdoc: [
        this.cliente?.cli_ente_sdoc,
        ConditionalValidator.predicate(() => this.formGroup.value.cli_sdoc && !this.formGroup.value.cli_sdoc_sportello, Validators.required),
      ],

      cli_sdoc_sla_am: [
        this.cliente?.cli_sdoc_sla_am ?? 0,
        ConditionalValidator.predicate(() => this.formGroup.value.cli_sdoc, Validators.required)
      ],
      cli_sdoc_sla_cp: [
        this.cliente?.cli_sdoc_sla_cp ?? 0,
        ConditionalValidator.predicate(() => this.formGroup.value.cli_sdoc, Validators.required)
      ],
      cli_sdoc_sla_eu: [
        this.cliente?.cli_sdoc_sla_eu ?? 0,
        ConditionalValidator.predicate(() => this.formGroup.value.cli_sdoc, Validators.required)
      ],
      cli_sdoc_gg_chiudi_am: [
        this.cliente?.cli_sdoc_gg_chiudi_am ?? 0,
        ConditionalValidator.predicate(() => this.formGroup.value.cli_sdoc, Validators.required)
      ],
      cli_sdoc_gg_chiudi_cp: [
        this.cliente?.cli_sdoc_gg_chiudi_cp ?? 0,
        ConditionalValidator.predicate(() => this.formGroup.value.cli_sdoc, Validators.required)
      ],
      cli_sdoc_gg_chiudi_eu: [
        this.cliente?.cli_sdoc_gg_chiudi_eu ?? 0,
        ConditionalValidator.predicate(() => this.formGroup.value.cli_sdoc, Validators.required)
      ],
      cli_sdoc_sla_r: [
        this.cliente?.cli_sdoc_sla_r ?? 0,
        ConditionalValidator.predicate(() => this.formGroup.value.cli_sdoc, Validators.required)
      ],
      cli_sdoc_chiudi_smistamento: [
        this.cliente?.cli_sdoc_chiudi_smistamento ?? 0,
        ConditionalValidator.predicate(() => this.formGroup.value.cli_sdoc, Validators.required)
      ],
      cli_sdoc_vede_linea: [
        this.cliente?.cli_sdoc_vede_linea ?? 0,
        ConditionalValidator.predicate(() => this.formGroup.value.cli_sdoc, Validators.required)
      ],
      cli_sdoc_controllo: [
        this.cliente?.cli_sdoc_controllo ?? 0,
        ConditionalValidator.predicate(() => this.formGroup.value.cli_sdoc, Validators.required)
      ],
      cli_sdoc_gg_controllo: [
        this.cliente?.cli_sdoc_gg_controllo ?? 0,
        ConditionalValidator.predicate(
          () => this.formGroup.value.cli_sdoc && this.formGroup.value.cli_sdoc_controllo,
          Validators.required
        )
      ],
      cli_sdoc_spedizione: [
        this.cliente?.cli_sdoc_spedizione ?? 0,
        ConditionalValidator.predicate(() => this.formGroup.value.cli_sdoc, Validators.required)
      ],
      cli_sdoc_sportello: [
        {
          value: this.cliente?.cli_sdoc_sportello ?? 0,
          disabled: this._userService.hasAnyRole(['sdoc_filiale', 'racc_filiale', 'parcel_filiale', 'atti_filiale'])
        },
        ConditionalValidator.predicate(() => this.formGroup.value.cli_sdoc, Validators.required)
      ],
      cli_sdoc_sportello_cod: [
        this.cliente?.cli_sdoc_sportello_cod ?? '',
        [ConditionalValidator.predicate(
          () => this.formGroup.value.cli_sdoc && this.formGroup.value.cli_sdoc_sportello,
          Validators.required
        ), Validators.maxLength(8)]
      ],
      cli_sdoc_tracciato_resi_dettagliato: [
        this.cliente?.cli_sdoc_tracciato_resi_dettagliato ?? 0,
        ConditionalValidator.predicate(() => this.formGroup.value.cli_sdoc, Validators.required)
      ],

      // Parcel
      cli_parcel_obbligodispacci: [
        this.cliente?.cli_parcel_obbligodispacci ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.cli_parcel, Validators.required)
      ],
      cli_parcel_ggslarecapito: [
        this.cliente?.cli_parcel_ggslarecapito ?? 0,
        ConditionalValidator.predicate(() => this.formGroup.value.cli_parcel, Validators.required)
      ],
      cli_parcel_riceveesitiftp: [
        this.cliente?.cli_parcel_riceveesitiftp ?? false,
        ConditionalValidator.predicate(() => this.formGroup.value.cli_parcel, Validators.required)
      ],
      cli_parcel_frequenzainvioesitiftp: [
        this.cliente?.cli_parcel_frequenzainvioesitiftp ?? 0,
        ConditionalValidator.predicate(
          () => this.formGroup.value.cli_parcel && this.formGroup.value.cli_parcel_riceveesitiftp,
          Validators.required
        )
      ],
      cli_parcel_giornispedizioneftp: [
        this.cliente?.cli_parcel_giornispedizioneftp ?? 0,
        ConditionalValidator.predicate(
          () => this.formGroup.value.cli_parcel && this.formGroup.value.cli_parcel_riceveesitiftp,
          Validators.compose([Validators.required, Validators.max(255)])
        )
      ],
      cli_parcel_ggalertpaccononpervenuto: [
        this.cliente?.cli_parcel_ggalertpaccononpervenuto ?? 0,
        ConditionalValidator.predicate(
          () => this.formGroup.value.cli_parcel,
          Validators.compose([Validators.required, Validators.max(255)])
        )
      ],
      cli_parcel_modo_fatturazione: [
        this.cliente?.cli_parcel_modo_fatturazione,
        ConditionalValidator.predicate(() => this.formGroup.value.cli_parcel,
          Validators.compose([Validators.required, Validators.max(3)])
        )
      ],
    });

  }

  public loggaErroriForm(): void {
    Object.keys(this.formGroup.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.formGroup.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }

  public onClientePadreCambiato(padre): void{
    this.padre = padre;
    if (padre){
      this.formGroup.controls['cli_appcod_sdoc'].setValue(padre.cli_appcod_sdoc);
    }
  }

  public async onSaveButtonClicked(): Promise<void> {
    if (this.formGroup.invalid) {
      return;
    }

    const body = this.formGroup.value;
    body.padre = this.padre?.cli_id;
    if (this.filialeRoyalty) {
      body.cli_filroyid = this.filialeRoyalty.fil_id;
    }
    body.figli = this.figli.map(cliente => cliente.cli_id);

    this._fuseProgressBarService.show();
    let response: ApiResponse<Cliente>;
    if (this.cliente) {
      response = await this._clientiApiService.update(this.cliente.cli_id, body);
    } else {
      response = await this._clientiApiService.create(this.formGroup.value);
    }
    this._fuseProgressBarService.hide();
    if (response) {
      this._toastr.success(response.message ?? 'Operazione eseguita con successo.', 'Successo!');
      this._location.back();
    }
  }

  public oneGestioneSelected(): boolean {
    return this.formGroup.value.cli_racc ||
      this.formGroup.value.cli_parcel ||
      this.formGroup.value.cli_sdoc ||
      this.formGroup.value.cli_atti;
  }

  public filtriClientePadre(): Query[] {
    const filters: Query[] = [{column: 'con_padre', search: false}];
    if (this.formGroup.value.cli_sdoc_sportello) {
      filters.push({column: 'cli_sdoc', search: 1});
    }
    return filters;
  }
}
