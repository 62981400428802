<div class="page-layout simple fullwidth">

  <!-- HEADER -->
  <div class="header px-64 py-24" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <span *ngIf="operazione"
            class="mat-chip"
            [class.atti-accent-foreground]="operazione.gestione === 'atti'"
            [class.parcel-accent-foreground]="operazione.gestione === 'parcel'"
            [class.raccomandate-accent-foreground]="operazione.gestione === 'racc'"
            [class.sdoc-accent-foreground]="operazione.gestione === 'sdoc'">{{operazione.gestione | uppercase}}</span>
      <h2 class="m-0">{{operazione ? (operazione.id + ' - ' + operazione.titolo.toUpperCase()) : 'Caricamento in corso…'}}</h2>
    </div>

    <mat-icon *ngIf="operazione?.stato === 'completato'" class="success" matTooltip="completato">check_circle</mat-icon>
    <mat-icon *ngIf="operazione?.stato === 'in corso'" matTooltip="in corso">sync</mat-icon>
    <mat-icon *ngIf="operazione?.stato === 'errore'" class="error"  matTooltip="errore">error_outline</mat-icon>
    <mat-icon *ngIf="operazione?.stato === 'attenzionato'" class="warning" matTooltip="completato con avvisi">warning_amber</mat-icon>
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div *ngIf="operazione" class="content px-64 py-24" fusePerfectScrollbar fxLayout="column">

    <!-- Operazioni eseguite -->
    <div class="fuse-card auto-width">
      <div class="p-24">
        <span class="mat-title m-0">Operazioni eseguite</span>
      </div>

      <mat-table [dataSource]="operazione.dettagli">
        <ng-container matColumnDef="sottotipo">
          <mat-header-cell *matHeaderCellDef>Tipo</mat-header-cell>
          <mat-cell *matCellDef="let operazioneDettaglio">{{operazioneDettaglio.sottotipo}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="messaggio">
          <mat-header-cell *matHeaderCellDef>Messaggio</mat-header-cell>
          <mat-cell *matCellDef="let operazioneDettaglio">{{operazioneDettaglio.messaggio}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="dtora_inizio">
          <mat-header-cell *matHeaderCellDef>Data inizio</mat-header-cell>
          <mat-cell *matCellDef="let operazioneDettaglio">{{operazioneDettaglio.dtora_inizio | moment}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="dtora_fine">
          <mat-header-cell *matHeaderCellDef>Data fine</mat-header-cell>
          <mat-cell *matCellDef="let operazioneDettaglio">{{operazioneDettaglio.dtora_fine | moment}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="stato">
          <mat-header-cell *matHeaderCellDef>Stato</mat-header-cell>
          <mat-cell *matCellDef="let operazioneDettaglio">
            <mat-icon *ngIf="operazioneDettaglio.stato === 'completato'" class="success" matTooltip="completato">check_circle</mat-icon>
            <mat-icon *ngIf="operazioneDettaglio.stato === 'in corso'" matTooltip="in corso">sync</mat-icon>
            <mat-icon *ngIf="operazioneDettaglio.stato === 'errore'" class="error" matTooltip="errore">error_outline</mat-icon>
            <mat-icon *ngIf="operazioneDettaglio.stato === 'attenzionato'" class="warning" matTooltip="completato con avvisi">warning_amber</mat-icon>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>Azioni</mat-header-cell>
          <mat-cell *matCellDef="let operazioneDettaglio">
            <button mat-icon-button color="accent" (click)="onShowDataButtonClicked(operazioneDettaglio)" [disabled]="!operazioneDettaglio.data">
              <mat-icon>visibility</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
        <mat-row *matRowDef="let operazione; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>
    <!-- / Operazioni eseguite -->

    <!-- Dati operazione -->
    <div *ngIf="operazione.data" class="fuse-card auto-width mt-24">
      <div class="p-24">
        <span class="mat-title m-0">Dati operazione</span>
      </div>

      <mat-table [dataSource]="operazione.data | keyvalue">
        <ng-container matColumnDef="key">
          <mat-cell *matCellDef="let data"><strong>{{data.key}}</strong></mat-cell>
        </ng-container>
        <ng-container matColumnDef="value">
          <mat-cell *matCellDef="let data"><app-operazione-dettaglio-data-value [value]="data.value"></app-operazione-dettaglio-data-value></mat-cell>
        </ng-container>

        <mat-row *matRowDef="let data; columns: ['key', 'value'];"></mat-row>
      </mat-table>
    </div>
    <!-- / Dati operazione -->

  </div>
  <!-- / CONTENT -->

</div>
