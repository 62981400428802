import {Component, OnDestroy, OnInit} from '@angular/core';
import {Operazione, OperazioneDettaglio, StatoOperazione} from '../../../models/entities/operazione';
import {OperazioniApiService} from '../../../services/api/operazioni-api.service';
import {FuseProgressBarService} from '../../../../../../@fuse/components/progress-bar/progress-bar.service';
import {ActivatedRoute} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {OperazioneDettaglioDataDialogComponent} from '../../dialogs/operazione-dettaglio-data-dialog/operazione-dettaglio-data-dialog.component';
import {EchoService} from 'ngx-laravel-echo';
import {UserService} from '../../../services/user.service';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Query} from '../../../models/paginator';
import {ColumnFilter, ColumnFilterType, RelationFilter} from '../../partials/filters/filters.component';
import {FuseSidebarService} from '../../../../../../@fuse/components/sidebar/sidebar.service';
import {HttpClient} from '@angular/common/http';
import {SystemsService} from '../../../services/systems.service';
import {OperazioniPaginatorService} from '../../../services/paginators/operazioni-paginator.service';
import {UsersPaginatorService} from '../../../services/paginators/users-paginator.service';
import {userIdentifier, userStringifier} from '../../../models/entities/user';
import {System} from '../../../models/entities/system';

@Component({
  selector: 'app-operazioni',
  templateUrl: './operazioni.component.html',
  styleUrls: ['./operazioni.component.scss']
})
export class OperazioniComponent implements OnInit, OnDestroy {
  private _queries = new BehaviorSubject<Query[]>(null);
  private systemQuery: Query;
  private _unsubscribeAll: Subject<any>;
  public gestione: string = System[this._systemsService.selectedSystem].toLowerCase();

  public displayedColumns: string[] = ['id', 'gestione', 'utente', 'titolo', 'tipo', 'stato', 'dtora_inizio', 'dtora_fine', 'actions'];
  public filters: (ColumnFilter | RelationFilter<any>)[];

  constructor(private _fuseProgressBarService: FuseProgressBarService,
              private _fuseSidebarService: FuseSidebarService,
              private _http: HttpClient,
              public operazioniPaginator: OperazioniPaginatorService,
              private _route: ActivatedRoute,
              private _systemsService: SystemsService,
              private _userService: UserService) {
  }

  ngOnInit(): void {
    this._unsubscribeAll = new Subject();

    this.operazioniPaginator.isLoading.pipe(takeUntil(this._unsubscribeAll)).subscribe(isLoading => {
      if (isLoading) {
        this._fuseProgressBarService.show();
      } else {
        this._fuseProgressBarService.hide();
      }
    });

    this.filters = [
      {
        label: 'Tipo',
        column: 'tipo',
        type: ColumnFilterType.STRING,
      }, {
        label: 'Titolo',
        column: 'titolo',
        type: ColumnFilterType.STRING,
      },
      {
        label: 'Stato',
        column: 'stato',
        type: ColumnFilterType.ARRAY, values: [
          {label: 'Tutti', value: null},
          {label: 'Completato', value: StatoOperazione.COMPLETATO},
          {label: 'In Corso', value: StatoOperazione.IN_CORSO},
          {label: 'Errore', value: StatoOperazione.ERRORE},
          {label: 'Con Avvisi', value:  StatoOperazione.ATTENZIONATO}
        ],
        defaultValue: null
      },
      {
        // questo è un relationalfilter
        label: 'Utente',
        column: 'user_id',
        paginator: new UsersPaginatorService(this._http),
        searchField: 'name',
        // mode: 'select', // questo li carica tutti
        // filters: [{column: 'username', search: this._userService.user.username}], // questo non è il filtro iniziale ma quello che verrà sempre aggiunto
        entityIdentifier: userIdentifier,
        entityStringifier: userStringifier,
        selectDefaultValue: this._userService.user,
      },
      /*a
      {
        label: 'Gestione',
        column: 'gestione',
        type: ColumnFilterType.ARRAY, values: [
          {label: 'SDOC', value: 'sdoc'},
          {label: 'Raccomandate', value: 'racc'},
          {label: 'Parcel', value: 'parcel'},
          {label: 'Atti Giudiziari', value: 'atti'}
        ],
        defaultValue:  System[this._systemsService.selectedSystem].toLowerCase()
      },
       */
    ];

    this.systemQuery = {column: 'gestione', search: (System[this._systemsService.selectedSystem ] === 'RACC' ? 'raccomandate' : System[this._systemsService.selectedSystem ])};
    // this.operazioniPaginator.includes = ['user'];

    // console.log('query iniziale', this.operazioniPaginator.queries.getValue());
    this._queries.pipe(takeUntil(this._unsubscribeAll)).subscribe(queries => {
      this.operazioniPaginator.queries.next([
        ...(queries ?? []),
         this.systemQuery,
        // {column: 'user_id', search: this._userService.user.id}
      ]);
    });
    this.operazioniPaginator.sort.next({active: 'id', direction: 'desc'});
    this.operazioniPaginator.queries.next([this.systemQuery, {column: 'user_id', search: this._userService.user.id}]); // filtri iniziali

  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  public toggleSidebar(name): void {
    this._fuseSidebarService.getSidebar(name).toggleOpen();
  }

  public onFiltersChanged(queries: Query[]): void {

    const queryUser = queries?.filter(query => (query.column === 'user_id'));
    if (queryUser) {
      console.log('cambiato filtro utente, queries: ', queries);
    }
    this._queries.next([ ... queries, this.systemQuery]);
  }
}
