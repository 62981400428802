import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-operazione-dettaglio-data-value',
  templateUrl: './operazione-dettaglio-data-value.component.html',
  styleUrls: ['./operazione-dettaglio-data-value.component.scss']
})
export class OperazioneDettaglioDataValueComponent implements OnInit {
  // tslint:disable-next-line:max-line-length
  private static URLREGEX = new RegExp(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/);

  public type: Type;

  @Input()
  public value: any;

  constructor() { }

  ngOnInit(): void {
    this.type = Type.SIMPLE;

    if (this.value instanceof Array) {
      this.type = Type.ARRAY;
      return;
    }
    if (this.value instanceof Object) {
      this.type = Type.OBJECT;
      return;
    }

    const matches = OperazioneDettaglioDataValueComponent.URLREGEX.exec(this.value);
    if (matches) {
      this.type = Type.URL;
    }else if ( this.value.indexOf('FRONTENDURL') >= 0){
      console.log('sostituito link');
      this.value = this.value.replace('FRONTENDURL', location.origin);
      this.type = Type.URL;
    }
  }
}

enum Type {
  ARRAY,
  OBJECT,
  SIMPLE,
  URL,
}
