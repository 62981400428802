import {Injectable} from '@angular/core';
import {User} from '../models/entities/user';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {ApiResponse} from '../models/api-response';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public user: User;
  public isLoading = new BehaviorSubject<boolean>(false);

  constructor(private _http: HttpClient) {
  }

  public async load(): Promise<void> {
    this.user = await this._http.get<User>(`${environment.baseUrl}api/utente`).toPromise();
  }

  public async changePassword(data: { password: string, password_confirmation: string }): Promise<ApiResponse<void>> {
    this.isLoading.next(true);
    let result = null;
    try {
      result = await this._http.post<ApiResponse<any>>(`${environment.baseUrl}api/cambio_password`, data).toPromise();
    } catch (e) {
    }
    this.isLoading.next(false);
    return result;
  }

  public can(action: string): boolean {
    return this.user.permissions.includes(action) ;
  }

  public hasRole(role: string): boolean {
    return this.user?.roles.includes(role);
  }
  public hasAnyRole(roles: string[]): boolean {
    const filteredArray = roles.filter(value => this.user?.roles.includes(value));
    return filteredArray.length > 0;
  }
}
