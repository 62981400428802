<button mat-icon-button [matMenuTriggerFor]="notificationsMenu">
  <mat-icon matBadge="{{notificationsService.unreadNotifications}}"
            [matBadgeHidden]="notificationsService.unreadNotifications === 0">notifications</mat-icon>
</button>

<mat-menu #notificationsMenu xPosition="before">
  <h4 *ngIf="!(notificationsService.isLoading | async) && notificationsService.notifications.length === 0"
      class="px-24">
    Nessuna notifica disponibile.
  </h4>

  <mat-list infiniteScroll
            [scrollWindow]="false"
            (scrolled)="notificationsService.loadNext()">
    <mat-list-item *ngFor="let notification of notificationsService.notifications"
                   class="selectable-row"
                   matRipple
                   [class.unread]="notification.read_at === null"
                   (click)="notificationsService.openNotification(notification)">
      <h3 matLine
          [class.atti-accent-foreground]="notification.gestione === 'atti'"
          [class.parcel-accent-foreground]="notification.gestione === 'parcel'"
          [class.raccomandate-accent-foreground]="notification.gestione === 'racc'"
          [class.sdoc-accent-foreground]="notification.gestione === 'sdoc'">
        {{notification.titolo}}
      </h3>

      <p matLine>{{notification.descrizione}}</p>
    </mat-list-item>

    <mat-list-item *ngIf="notificationsService.isLoading | async">
      <mat-progress-spinner color="accent" diameter="24" mode="indeterminate"></mat-progress-spinner>
    </mat-list-item>
  </mat-list>
</mat-menu>
