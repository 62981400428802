import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {ValidationDialogComponent} from '../components/dialogs/validation-dialog/validation-dialog.component';
import {UserService} from '../services/user.service';
import {ToastrService} from 'ngx-toastr';
import {SimpleAlertDialogComponent} from '../components/dialogs/simple-alert-dialog/simple-alert-dialog.component';
import {Router} from '@angular/router';

@Injectable()
export class HttpErrorsInterceptor implements HttpInterceptor {

  constructor(private _dialog: MatDialog,
              private _router: Router,
              private _toastr: ToastrService,
              private _userService: UserService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        if (error.status === 400 && error.error?.message) {
          const messages = [];
          messages.push(error.error.message);
          this._handleValidationErrors(messages);
        }
        if (error.status === 401) {
          this._handleUnauthenticated();
        }
        if (error.status === 403) {
          this._handleUnauthorizedErrors();
        }
        if (error.status === 404) {
          this._handleNotFoundErrors(error.error?.message);
        }
        if (error.status === 422 && error.error?.errors) {
          const messages = [];
          for (const message of Object.values(error.error.errors)) {
            messages.push(message);
          }
          this._handleValidationErrors(messages);
        }
        if ((error.status >= 500 && error.status <= 599) || !error.status) {
          this._handleServerError(error.error?.message ?? null, error.error?.exception);
        }

        return throwError(error);
      })
    );
  }

  private _handleUnauthenticated(): void {
    this._toastr.error('Non sei autenticato. Reindirizzamento alla schermata di login…').onHidden.toPromise().then(() => {
      this._router.navigate(['/login']);
    });
  }

  private _handleUnauthorizedErrors(): void {
    const toast = this._toastr.error('Non siete autorizzati ad eseguire l\'azione richiesta', 'Attenzione!');

    const subscription = toast.onTap.subscribe(() => {
      this._dialog.open(SimpleAlertDialogComponent, {
        data: {
          title: 'Non Autorizzati',
          content: 'Non siete autorizzati ad eseguire l\'azione richiesta, contattare un amministratore per eventuali delucidazioni',
        }
      });
    });
    toast.onHidden.toPromise().then(() => {
      subscription.unsubscribe();
    });
  }

  private _handleNotFoundErrors(message: string): void {
    const toast = this._toastr.warning('I dati richiesti al server non sono disponibili.', 'Attenzione!');

    const subscription = toast.onTap.subscribe(() => {
      this._dialog.open(SimpleAlertDialogComponent, {
        data: {
          title: 'Not Found',
          content: message ?? 'La risorsa o i dati richiesti non sono stati trovati',
        }
      });
    });
    toast.onHidden.toPromise().then(() => {
      subscription.unsubscribe();
    });
  }

  private _handleValidationErrors(messages: string[]): void {
    const toast = this._toastr.warning('I dati inviati al server non sono validi. Fai click qui per vederne i dettagli.', 'Attenzione!');

    const subscription = toast.onTap.subscribe(() => {
      this._dialog.open(ValidationDialogComponent, {
        data: {
          messages: messages,
        }
      });
    });
    toast.onHidden.toPromise().then(() => {
      subscription.unsubscribe();
    });
  }

  private _handleServerError(message: string | null, exception: string | null): void {
    const toast = this._toastr.error('Si è verificato un errore imprevisto.', 'Errore!');

    const subscription = toast.onTap.subscribe(() => {
      if (message) {
        this._dialog.open(SimpleAlertDialogComponent, {
          data: {
            title: message,
            content: exception ?? '',
          }
        });
      }
    });
    toast.onHidden.toPromise().then(() => {
      subscription.unsubscribe();
    });
  }
}
