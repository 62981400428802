import {Injectable} from '@angular/core';
import {ApiService} from '../../models/api-service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Notification} from '../../models/entities/notification';
import {ApiResponse} from '../../models/api-response';

@Injectable({
  providedIn: 'root'
})
export class NotificationsApiService extends ApiService<Notification> {

  constructor(http: HttpClient) {
    super(http, `${environment.baseUrl}api/notifiche`);
  }

  public async markAsRead(id: string): Promise<ApiResponse<Notification>> {
    this.isLoading.next(true);
    let result = null;
    try {
      result = await this.http.post<ApiResponse<Notification>>(`${this.url}/${id}/letta`, null).toPromise();
    } catch (e) {
    }
    this.isLoading.next(false);
    return result;
  }
}
