import {Component, Inject} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-datetimepicker-dialog',
  templateUrl: './datetimepicker-dialog.component.html',
  styleUrls: ['./datetimepicker-dialog.component.scss']
})
export class DatetimepickerDialogComponent {
  public formGroup: FormGroup;
  title: string;

  constructor(private _dialogRef: MatDialogRef<DatetimepickerDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Payload,
              private _formBuilder: FormBuilder) {
    if (data && data.title) {
      this.title = data.title;
    } else {
      this.title = 'Seleziona una data';
    }
    this.formGroup = this._formBuilder.group({
      dtora: new FormControl('', Validators.required)
    });
  }

  public get isValid(): boolean {
    return this.formGroup.valid;
  }

  public onConfirmButtonClicked(): void {
    if (!this.formGroup.value) {
      return;
    }


    this._dialogRef.close(this.formGroup.value);
  }
}

export interface Payload {
  title: string;
}
