import {Component, OnDestroy, OnInit} from '@angular/core';
import {FilialiPaginatorService} from '../../../services/paginators/filiali-paginator.service';
import {FuseProgressBarService} from '../../../../../../@fuse/components/progress-bar/progress-bar.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {FuseSidebarService} from '../../../../../../@fuse/components/sidebar/sidebar.service';
import {ColumnFilter, ColumnFilterType, RelationFilter} from '../../partials/filters/filters.component';
import {Query} from '../../../models/paginator';
import {System} from '../../../models/entities/system';
import {SystemsService} from '../../../services/systems.service';
import {filialeIdentifier, filialeStringifier} from '../../../models/entities/filiale';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-filiali',
  templateUrl: './filiali.component.html',
  styleUrls: ['./filiali.component.scss']
})
export class FilialiComponent implements OnInit, OnDestroy {
  private _queries = new BehaviorSubject<Query[]>(null);
  private systemQuery: Query;
  private _unsubscribeAll: Subject<any>;

  public displayedColumns: string[];
  public filters: (ColumnFilter | RelationFilter<any>)[];

  constructor(private _fuseProgressBarService: FuseProgressBarService,
              private _fuseSidebarService: FuseSidebarService,
              private _http: HttpClient,
              private _systemsService: SystemsService,
              public filialiPaginator: FilialiPaginatorService) { }

  ngOnInit(): void {
    this._unsubscribeAll = new Subject();

    this.filialiPaginator.isLoading.pipe(takeUntil(this._unsubscribeAll)).subscribe(isLoading => {
      if (isLoading) {
        this._fuseProgressBarService.show();
      } else {
        this._fuseProgressBarService.hide();
      }
    });

    this.filters = [
      {label: 'Nome', column: 'fil_nome', type: ColumnFilterType.STRING},
      {
        label: 'Filiale madre',
        column: 'madre',
        paginator: new FilialiPaginatorService(this._http),
        filters: [{column: 'madri', search: true}],
        searchField: 'fil_nome',
        entityIdentifier: filialeIdentifier,
        entityStringifier: filialeStringifier,
      },
      {
        label: 'Tipo filiale madre',
        column: 'tipo_madre',
        type: ColumnFilterType.ARRAY, values: [
          {label: 'Tutte', value: null},
          {label: 'Circuito', value: 'C'},
          {label: 'Distribuzione', value: 'D'},
          {label: 'Hub', value: 'H'}
        ],
        defaultValue: null
      },
      {
        label: 'Attive',
        column: 'fil_attiva',
        type: ColumnFilterType.ARRAY, values: [
          {label: 'Tutte', value: null},
          {label: 'Sì', value: true},
          {label: 'No', value: false}
        ],
        defaultValue: true
      },
      {
        label: 'Capo area',
        column: 'fil_capoarea',
        type: ColumnFilterType.ARRAY, values: [
          {label: 'Tutte', value: null},
          {label: 'Sì', value: true},
          {label: 'No', value: false}
        ]
      },
      {
        label: 'Hub regionale',
        column: 'fil_hub_regionale',
        type: ColumnFilterType.ARRAY, values: [
          {label: 'Tutte', value: null},
          {label: 'Sì', value: true},
          {label: 'No', value: false}
        ]
      },
      {
        label: 'Hub nazionale',
        column: 'fil_hub_nazionale',
        type: ColumnFilterType.ARRAY, values: [
          {label: 'Tutte', value: null},
          {label: 'Sì', value: true},
          {label: 'No', value: false}
        ]
      },
      {
        label: 'Scambio flussi',
        column: 'fil_scambio_flussi',
        type: ColumnFilterType.ARRAY, values: [
          {label: 'Tutte', value: null},
          {label: 'Sì', value: true},
          {label: 'No', value: false}
        ]
      },
      {
        label: 'Punto giacenza',
        column: 'fil_puntogiacenza',
        type: ColumnFilterType.ARRAY, values: [
          {label: 'Tutte', value: null},
          {label: 'Sì', value: true},
          {label: 'No', value: false}
        ]
      },
      {
        label: 'Gestione',
        column: 'gestione',
        type: ColumnFilterType.ARRAY, values: [
          {label: 'SDOC', value: 'sdoc'},
          {label: 'Raccomandate', value: 'racc'},
          {label: 'Parcel', value: 'parcel'},
          {label: 'Atti Giudiziari', value: 'atti'}
        ],
        defaultValue: {column: 'gestione', search: System[this._systemsService.selectedSystem]}
      },
    ];


    this.systemQuery = {column: 'gestione', search: System[this._systemsService.selectedSystem]};
    this.filialiPaginator.queries.next([this.systemQuery]);
    this._queries.pipe(takeUntil(this._unsubscribeAll)).subscribe(queries => {
      this.filialiPaginator.queries.next([
        ...(queries ?? []),
        this.systemQuery
      ]);
    });

    switch (this._systemsService.selectedSystem) {
      case System.ATTI:
        this.displayedColumns = ['fil_id', 'fil_nome', 'fil_capoarea', 'fil_puoimportare', 'fil_cap', 'fil_localita', 'fil_provincia', 'fil_codcliarca', 'fil_codforarca', 'fil_attiva', 'fil_piva', 'fil_gestitadasede', 'fil_gesttariffe', 'fil_vedeesitibarcode', 'fil_euroroyaltyrete', 'updated_at', 'actions'];
        break;

      case System.PARCEL:
        this.displayedColumns = ['fil_id', 'fil_nome', 'fil_capoarea', 'fil_scambio_flussi', 'fil_puoimportare', 'fil_cap', 'fil_localita', 'fil_provincia', 'fil_codcliarca', 'fil_codforarca', 'fil_attiva', 'fil_piva', 'fil_gestitadasede', 'fil_hub_regionale', 'fil_hub_nazionale', 'fil_gesttariffe', 'fil_vedeesitibarcode', 'fil_euroroyaltyrete', 'fil_modofatturazione', 'fil_vede_royalty_filiale', 'updated_at', 'actions'];
        break;

      case System.RACC:
        this.displayedColumns = ['fil_id', 'fil_nome', 'fil_capoarea', 'fil_puoimportare', 'fil_cap', 'fil_localita', 'fil_provincia', 'fil_codcliarca', 'fil_codforarca', 'fil_attiva', 'fil_piva', 'fil_gestitadasede', 'fil_gesttariffe', 'fil_vedeesitibarcode', 'fil_euroroyaltyrete', 'updated_at', 'actions'];
        break;

      case System.SDOC:
        this.displayedColumns = ['fil_id', 'fil_nome', 'fil_descrizione', 'fil_scambio_flussi', 'fil_puoimportare', 'fil_cap', 'fil_localita', 'fil_provincia', 'fil_codforarca', 'fil_attiva', 'fil_non_smistabile', 'fil_piva', 'updated_at', 'actions'];
        break;
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  public toggleSidebar(name): void {
    this._fuseSidebarService.getSidebar(name).toggleOpen();
  }

  public onFiltersChanged(queries: Query[]): void {
    const queryGestione = queries.filter(query => (query.column === 'gestione'));
    if (queryGestione) {
      this.systemQuery = queryGestione[0];
    }
    this._queries.next(queries);
  }
}
