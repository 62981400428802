import {Injectable} from '@angular/core';
import {ApiService} from '../../models/api-service';
import {Filiale} from '../../models/entities/filiale';
import {environment} from '../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Types} from "../../models/entities/system";
import { Manutenzione } from '../../models/entities/manutenzione';

@Injectable({
  providedIn: 'root'
})
export class ManutenzioneApiService extends ApiService<Manutenzione> {

  constructor(http: HttpClient) {
    super(http, `${environment.nodeUrl}`);
  }

  public async startUpdating(id: number | string): Promise<string> {
    this.isLoading.next(true);
    let result = null;
    try {
      result = await this.http.post<string>(this.url,{id:id}).toPromise();
    } catch (e) {
    }
    this.isLoading.next(false);
    return result;
  }

  public async startTruncate(id: number | string): Promise<string> {
    this.isLoading.next(true);
    let result = null;
    try {
      result = await this.http.post<string>(this.url+'api/truncate',{id:id}).toPromise();
    } catch (e) {
    }
    this.isLoading.next(false);
    return result;
  }
}
