<h1 mat-dialog-title>{{title}}</h1>
<mat-dialog-content>
  <mat-form-field appearance="outline" fxFlex>
    <mat-label>{{label}}</mat-label>
    <mat-select [required]="required" [formControl]="formControl">
      <mat-option *ngFor="let entity of options" [value]="entity.value">
        {{entity.label}}
      </mat-option>
    </mat-select>
    <mat-error>Questo campo è obbligatorio</mat-error>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end" fxLayoutGap="16px">
  <button mat-flat-button mat-dialog-close>Annulla</button>
  <button mat-raised-button (click)="onConfirmButtonClicked()" color="accent" [disabled]="!formControl.value">Conferma</button>
</mat-dialog-actions>
