import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { Paginator, Query } from "../../../models/paginator";
import { FormBuilder, FormGroup } from "@angular/forms";
import { EntitySelectorComponent } from "../entity-selector/entity-selector.component";
import { LocalStorageService } from "ngx-webstorage";
import { Router } from "@angular/router";
import * as moment from "moment";
import { COMMA, TAB } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
import { MultiEntitySelectorDialogComponent } from "../../dialogs/multi-entity-selector-dialog/multi-entity-selector-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { TableColumn } from "../../../models/table-column";
import { Subject } from "rxjs";
import { pairwise, startWith, takeUntil } from "rxjs/operators";
import { ENTER } from "@angular/cdk/keycodes/keycodes";

@Component({
  selector: "app-filters",
  templateUrl: "./filters.component.html",
  styleUrls: ["./filters.component.scss"],
})
export class FiltersComponent<T> implements OnInit, OnDestroy {
  public selectable = true;
  public removable = true;
  public addOnBlur = true;
  readonly separatorKeysCodes: number[] = [TAB, COMMA, 13];
  public chipContents = [];
  public mostraForm = false;

  @ViewChildren("entitySelector")
  private _entitySelectors: QueryList<EntitySelectorComponent<any>>;

  public formGroup: FormGroup;

  @Input()
  public disableEnter = false;

  @Input()
  public filters: (
    | ColumnFilter
    | RelationFilter<any>
    | MultiRelationFilter<any>
  )[] = [];

  @Input()
  public paginator: Paginator<T>;

  @Output()
  public filtersChanged = new EventEmitter<Query[]>();

  private _unsubscribeAll: Subject<any>;

  constructor(
    private formBuilder: FormBuilder,
    private _localStorage: LocalStorageService,
    private _router: Router,
    private dialog: MatDialog
  ) { }

  private static isColumnFilter(filter: any): filter is ColumnFilter {
    return filter.hasOwnProperty("type");
  }

  private static isRelationFilter(filter: any): filter is RelationFilter<any> {
    return (
      filter.hasOwnProperty("entityIdentifier") &&
      !filter.hasOwnProperty("multiSelect")
    );
  }

  private static isMultiRelationFilter(
    filter: any
  ): filter is MultiRelationFilter<any> {
    return (
      filter.hasOwnProperty("entityIdentifier") &&
      filter.hasOwnProperty("multiSelect")
    );
  }

  private _hasFilterForValue(filter: string): boolean {
    let oldFilters = sessionStorage.getItem("existingFilters");
    if (oldFilters && JSON.parse(oldFilters).url == this._router.url) {
      const fil = JSON.parse(sessionStorage.getItem("existingFilters")).filters as {
        column: string;
        search: string;
      }[];

      const keys = fil.map((a) => a.column);
      return keys.includes(filter);
    }

    return false;
  }

  private _filterForColumn(filter: string): any {
    let oldFilters = sessionStorage.getItem("existingFilters");
    if (oldFilters && JSON.parse(oldFilters).url == this._router.url) {
      const fil = JSON.parse(sessionStorage.getItem("existingFilters")).filters as {
        column: string;
        search: string;
      }[];
      const val = fil.find((a) => a.column === filter);
      return val;
    }

    return "";
  }

  ngOnInit(): void {
    this._unsubscribeAll = new Subject();

    const group = {};
    for (const filter of this.filters) {
      filter["value"] = this._hasFilterForValue(filter.column)
        ? this._filterForColumn(filter.column).formValue
        : null;

      /*
      if (FiltersComponent.isColumnFilter(filter)){
        console.log('MultiRelationFilter(' + filter.column  + ').defaultValue', filter.defaultValue);
      }
      if (FiltersComponent.isRelationFilter(filter)){
        console.log('RelationFilter(' + filter.column  + ').selectDefaultValue', filter.selectDefaultValue);
      }
      if (FiltersComponent.isMultiRelationFilter(filter)){
        console.log('MultiRelationFilter(' + filter.column  + ').selectedValues', filter.selectedValues);
      }
      */

      group[filter.column] = [
        {
          value:
            this._hasFilterForValue(filter.column) &&
              FiltersComponent.isColumnFilter(filter)
              ? this._filterForColumn(filter.column).search
              : FiltersComponent.isColumnFilter(filter) &&
                filter.defaultValue !== undefined
                ? filter.defaultValue
                : FiltersComponent.isMultiRelationFilter(filter) &&
                  filter.selectedValues?.length > 0
                  ? filter.selectedValues
                  : this._hasFilterForValue(filter.column)
                    ? this._filterForColumn(filter.column).formValue
                    : "",
          // ( FiltersComponent.isRelationFilter(filter) && filter.selectDefaultValue !== undefined ? filter.selectDefaultValue : '' )
          disabled: FiltersComponent.isColumnFilter(filter)
            ? filter.disabled
            : false,
        },
      ];
    }

    this.formGroup = this.formBuilder.group(group);

    for (const filter of this.filters) {
      if (
        FiltersComponent.isColumnFilter(filter) &&
        filter.enabledOtherFilterIfValue
      ) {
        this.formGroup.controls[filter.column].valueChanges
          .pipe(takeUntil(this._unsubscribeAll), startWith(0), pairwise())
          .subscribe(([prev, next]: [any, any]) => {
            const valueEnable = filter.enabledOtherFilterIfValue.valueEnable;
            const altroFiltroAbilitare =
              filter.enabledOtherFilterIfValue.otherFilterColumn;

            /*
            console.log(filter.column); // your FormControl Identifier
            console.log('valore prec', prev);
            console.log('valore nuovo', next);
            console.log('abilitare ' + altroFiltroAbilitare + ' se ' + filter.column + '.value =' + valueEnable);
            */

            if (next === valueEnable) {
              this.formGroup.controls[altroFiltroAbilitare].enable();
            } else {
              this.formGroup.controls[altroFiltroAbilitare].disable();
            }
          });
      }
    }
    this.mostraForm = true;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  public onClearButtonClicked(): void {
    this.formGroup.reset();
    this._entitySelectors.map((entitySelector) =>
      entitySelector.formControl.reset()
    );
    this.filtersChanged.emit(null);
    this.paginator?.queries.next(null);
  }

  public addSpacesIfNeeded(stringa): string {
    const pos = stringa.indexOf(" ");
    if (stringa.length > 60) {
      stringa =
        stringa.substr(0, 24) +
        " " +
        stringa.substr(24, 24) +
        " " +
        stringa.substr(48, 100);
    } else if (pos === -1 || pos > 30) {
      stringa = stringa.substr(0, 20) + " " + stringa.substr(20, 20);
    }
    return stringa;
  }

  public impostaFiltroSuEventualeFiltroAbbinato(filtro, valore): void {
    if (filtro.filtriAbbinati) {
      const newValue = this.formGroup.controls[filtro.column].value
        ? filtro.entityIdentifier(this.formGroup.controls[filtro.column].value)
        : null;

      // console.log('controllo filtri abbinati, per nuovo valore '+ filtro.column, newValue );
      this.filters.forEach((filter) => {
        filtro.filtriAbbinati.forEach((filtroAbbinato) => {
          if (filter.label === filtroAbbinato.label) {
            // console.log('trovato filtro abbinato ', filter);
            if (FiltersComponent.isRelationFilter(filter)) {
              let trovato = false;
              filter.filters?.forEach((filtroBis) => {
                if (filtroBis.column === filtroAbbinato.column) {
                  trovato = true;
                  if (newValue) {
                    filtroBis.search = newValue;
                  } else {
                    // devo togliere l'elemento dai filtri
                    filter.filters = filter.filters.filter(
                      (res) => res.column !== filtroAbbinato.column
                    );
                  }
                }
              });
              if (!trovato && newValue) {
                if (filter.filters) {
                  filter.filters = [
                    ...filter.filters,
                    { column: filtroAbbinato.column, search: newValue },
                  ];
                } else {
                  filter.filters = [
                    { column: filtroAbbinato.column, search: newValue },
                  ];
                }
              }
              // console.log('nuovo filtro impostati su filtro ' + filtroAbbinato.label, filter.filters);
            }
          }
        });
      });
    }
  }

  public onConfirmButtonClicked(): void {
    const queries = [];
    console.log("confirm button clicked");
    for (const filter of this.filters) {
      if (
        FiltersComponent.isColumnFilter(filter) &&
        filter.type === ColumnFilterType.MULTIPLE_STRING
      ) {
        const search = filter.trim
          ? this.chipContents
            .map((str) => str.replace("#", "%23"))
            .join(",")
            .replace(/\s/g, "")
            .trim()
          : this.chipContents.map((str) => str.replace("#", "%23")).join(","); // /\s/g, toglie gli spazi in mezzo che nel casi dei barcode mi sa che non va bene

        if (search !== "") {
          queries.push({
            column: filter.column,
            search: search,
            formValue: this.formGroup.value[filter.column],
          });
        }
        // console.log('chipContents', this.chipContents);
        // console.log('search', search);
      } else if (
        FiltersComponent.isMultiRelationFilter(filter) && this.formGroup.value[filter.column] &&
        this.formGroup.value[filter.column].length > 0
      ) {
        queries.push({
          column: filter.column,
          search: this.formGroup.value[filter.column]
            ?.map((entity) => filter.entityIdentifier(entity))
            .join(","),
          formValue: this.formGroup.value[filter.column],
        });
      } else if (this.formGroup.value[filter.column]?.toString()?.length > 0) {
        let search = this.formGroup.value[filter.column];

        if (FiltersComponent.isRelationFilter(filter)) {
          search = filter.entityIdentifier(this.formGroup.value[filter.column]);
        }
        if (
          FiltersComponent.isColumnFilter(filter) &&
          filter.type === ColumnFilterType.DATE
        ) {
          search = moment(this.formGroup.value[filter.column]).format(
            "YYYY-MM-DD"
          );
        }

        queries.push({
          column: filter.column,
          search,
          formValue: this.formGroup.value[filter.column],
        });
      }
    }

    sessionStorage.setItem("existingFilters", JSON.stringify({ filters: queries, url: this._router.url }));
    this.paginator?.queries.next(queries);
    this.filtersChanged.emit(queries);
  }

  public addChip(event: MatChipInputEvent): void {
    const input = event.input;
    if (input && input.value !== "") {
      input.value.split(/\r?\n/).forEach((value) => {
        this.chipContents.push(value);
        console.log("chip aggiunto");
      });
      input.value = "";
    }
  }

  public addPasteChip(event: ClipboardEvent): void {
    event.preventDefault();
    event.clipboardData
      .getData("Text")
      .split(/\r?\n/)
      .forEach((value) => {
        if (value.trim()) {
          this.chipContents.push(value);
          console.log("chip aggiunto");
        }
      });
  }

  public removeChip(value: any): void {
    const index = this.chipContents.indexOf(value);

    if (index >= 0) {
      this.chipContents.splice(index, 1);
    }
  }

  public clearAllChips(): void {
    this.chipContents = [];
    console.log("chips cleared");
  }

  public async onAddButtonClicked(
    filter: MultiRelationFilter<any>
  ): Promise<void> {
    const result = await this.dialog
      .open(MultiEntitySelectorDialogComponent, {
        data: {
          columns: filter.columns,
          displayedColumns: filter.displayedColumns,
          paginator: filter.paginator,
          multiSelect: filter.multiSelect,
          searchFields: filter.searchFields,
          filters: filter.filters,
        },
      })
      .afterClosed()
      .toPromise();
    if (result) {
      this.formGroup.controls[filter.column].setValue(result);
    }
  }

  public onEntityRemoved(
    index: number,
    filter: MultiRelationFilter<any>
  ): void {
    this.formGroup.value[filter.column].splice(index, 1);
  }
}

export interface ColumnFilter {
  column: string;
  label: string;
  type: ColumnFilterType;
  disabled?: boolean;
  defaultValue?: any;
  values?: ArrayValue[];
  trim?: boolean;
  enabledOtherFilterIfValue?: AbilitaFiltroCollegato;
  preventKeyEnter?: boolean;
}

export interface RelationFilter<T> {
  column: string;
  entityIdentifier: (entity: T) => number | string;
  entityStringifier: (entity: T) => string;
  label: string;
  paginator: Paginator<T>;
  searchField: string;
  filters?: Query[];
  mode?: string;
  selectDefaultValue?: T;
  filtriAbbinati?: FiltroAbbinato[];
}

export interface MultiRelationFilter<T> {
  column: string;
  columns: TableColumn[];
  displayedColumns: string[];
  entityIdentifier: (entity: T) => number | string;
  entityStringifier: (entity: T) => string;
  label: string;
  paginator: Paginator<T>;
  filters?: Query[];
  multiSelect: boolean;
  searchFields: string[];
  disabled: boolean;
  required: boolean;
  selectedValues?: [T];
}

export interface ArrayValue {
  label: string;
  value: any;
}

export interface AbilitaFiltroCollegato {
  otherFilterColumn: string;
  valueEnable: any;
}

export interface FiltroAbbinato {
  label: string;
  column: string;
}

export enum ColumnFilterType {
  STRING = "string",
  NUMBER = "number",
  BOOLEAN = "boolean",
  DATE = "date",
  ARRAY = "array",
  MULTIPLE_STRING = "multiple_string",
}
