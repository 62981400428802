<div class="h-100-p" fxLayout="column" fxLayoutAlign="space-between">
  <form *ngIf="formGroup && mostraForm" [formGroup]="formGroup" fxLayout="column" (keydown.enter)="disableEnter ? $event.preventDefault() : onConfirmButtonClicked(); ">
    <h2 class="my-24">SELEZIONA I FILTRI</h2>

    <ng-container *ngFor="let filter of filters">
      <mat-form-field *ngIf="!filter.paginator && filter.type === 'string'" appearance="outline">
        <mat-label>{{filter.label}}</mat-label>
        <input matInput [formControlName]="filter.column">
      </mat-form-field>

      <mat-form-field *ngIf="!filter.paginator && filter.type === 'number'" appearance="outline">
        <mat-label>{{filter.label}}</mat-label>
        <input matInput type="number" [formControlName]="filter.column">
      </mat-form-field>

      <mat-checkbox *ngIf="!filter.paginator && filter.type === 'boolean'"
                    [disabled]="filter.disabled ? filter.disabled : false"
                    [formControlName]="filter.column">
        {{filter.label}}
      </mat-checkbox>

      <mat-form-field *ngIf="!filter.paginator && filter.type === 'date'" appearance="outline">
        <mat-label>{{filter.label}}</mat-label>
        <input matInput [formControlName]="filter.column" [matDatepicker]="datePicker">
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field *ngIf="!filter.paginator && filter.type === 'array'"  appearance="outline">
        <mat-label>{{filter.label}}</mat-label>
        <mat-select [formControlName]="filter.column" >
          <mat-option *ngFor="let value of filter.values" [value]="value.value">{{value.label}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="!filter.paginator && filter.type === 'multiple_string'" appearance="outline">
        <mat-label>{{filter.label}}</mat-label>
        <mat-chip-list #chipList aria-label="selection">
          <mat-chip [ngClass]="string.length > 60 ? 'alto' : 'normale'" *ngFor="let string of chipContents" [selectable]="selectable"
                    [removable]="removable" (removed)="removeChip(string)">
            {{addSpacesIfNeeded(string)}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <mat-chip *ngIf="chipContents.length > 0"
                    color="warn"
                    [selectable]="selectable"
                    (click)="clearAllChips()">
            Pulisci Filtro
            <mat-icon matChipRemove>cached</mat-icon>
          </mat-chip>
          <input placeholder="Scrivi qui..."
                 [formControlName]="filter.column"
                 [matChipInputFor]="chipList"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [matChipInputAddOnBlur]="addOnBlur"
                 (matChipInputTokenEnd)="addChip($event)"
                 (paste)="addPasteChip($event)">
        </mat-chip-list>
      </mat-form-field>

      <app-entity-selector #entitySelector
                           *ngIf="filter.paginator && !filter.multiSelect"
                           [label]="filter.label"
                           [paginator]="filter.paginator"
                           [searchField]="filter.searchField"
                           [filters]="filter.filters"
                           [disabled]="filter.disabled"
                           [value]="filter.value"
                           [mode]="filter.mode ? filter.mode : 'autocomplete'"
                           [selectDefaultValue]="filter.selectDefaultValue ? filter.selectDefaultValue : null"
                           (valueChanges)="formGroup.controls[filter.column].setValue($event); impostaFiltroSuEventualeFiltroAbbinato(filter,$event)"
                           [entityStringifier]="filter.entityStringifier"></app-entity-selector>

      <mat-form-field *ngIf="filter.multiSelect" class="w-100-p" appearance="outline">
        <mat-label>{{filter.label}}</mat-label>

        <mat-chip-list #chipList>
          <mat-chip *ngFor="let entity of formGroup.value[filter.column]; let i = index"
                    [removable]="!filter.disabled"
                    (removed)="onEntityRemoved(i, filter)">
            {{filter.entityStringifier(entity)}}
            <mat-icon *ngIf="!filter.disabled" matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>

        <button *ngIf="!filter.disabled" mat-icon-button matSuffix (click)="$event.preventDefault(); onAddButtonClicked(filter)">
          <mat-icon>add</mat-icon>
        </button>

        <input matInput readonly [matChipInputFor]="chipList" [required]="filter.required">
      </mat-form-field>
    </ng-container>
  </form>
  <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="16px">
    <button mat-raised-button class="w-50-p" color="primary" (click)="onConfirmButtonClicked()">CONFERMA</button>

    <button mat-flat-button class="w-50-p" (click)="onClearButtonClicked()">Rimuovi filtri</button>
  </div>
</div>
