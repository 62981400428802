import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Paginator, Query} from '../../../models/paginator';
import {Subscription} from 'rxjs';
import {debounceTime, filter} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';

@Component({
  selector: 'app-entity-selector',
  templateUrl: './entity-selector.component.html',
  styleUrls: ['./entity-selector.component.scss']
})
export class EntitySelectorComponent<T> implements OnInit, OnDestroy {
  private _subscription: Subscription;
  private _disabled = false;
  private _value: T;
  public selectArray: T[];

  public formControl: FormControl;

  @Input()
  public set disabled(value: boolean) {
    this._disabled = value;
    if (this._disabled) {
      this.formControl?.disable();
    } else {
      this.formControl?.enable();
    }
    // console.log(this.searchField + ' disabled', this.formControl?.disabled);
  }

  public get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  public entityStringifier: (entity: T) => string;

  @Input()
  public label: string;

  @Input()
  public paginator: Paginator<T>;

  @Input()
  public required = false;

  @Input()
  public searchField: string;

  @Input()
  public filters: Query[];

  @Output()
  public valueChanges = new EventEmitter<T>();

  @Input()
  public set value(value: T) {
    this._value = value;
    this.valueChanges.emit(value);
    this.formControl?.setValue(this.value ? this.entityStringifier(this.value) : '', {emitEvent: false});
  }

  public get value(): T {
    return this._value;
  }

  @Input()
  public mode = 'autocomplete';

  @Input()
  public selectDefaultValue = null;


  constructor() {
  }

  async ngOnInit(): Promise<void> {
    this.formControl = new FormControl({
      value: this.value ? this.entityStringifier(this.value) : '',
      disabled: this._disabled
    });
    await this.loadData();

  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  public clear(): void {
    if (this.value) {
      this.value = null;
    }
    this.formControl.setValue('', {emitEvent: false});
  }

  public onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    if (event.option) {
      this.value = event.option.value;
      this.formControl.setValue(this.entityStringifier(event.option.value), {emitEvent: false});
    } else {
      this.value = null;
    }
  }

  public compareEntity(object1: any, object2: any): boolean {
    return JSON.stringify(object1) === JSON.stringify(object2);
  }

  public async loadData(): Promise<void> {
    switch (this.mode) {
      case 'select':
        this.selectArray = await this.paginator.loadAll(
          [...(this.filters ?? [])],
          null,
          true
        );
        if (!this._disabled) {
          this._disabled = this.selectArray.length === 0;
        }
        if (this.selectDefaultValue) {
          this.selectArray.unshift(this.selectDefaultValue);
        }
        break;

      default:

        this._subscription = this.formControl.valueChanges.pipe(
          debounceTime(500),
          filter(value => typeof value === 'string')
        ).subscribe(async value => {
          await this.paginator.loadPage(
            0,
            [...(this.filters ?? []), {column: this.searchField, search: value}],
            null
          );
        });
        if (this.mode === 'autocomplete' && this.selectDefaultValue){
          this.value = this.selectDefaultValue;
          this.formControl.setValue(this.entityStringifier(this.value), {emitEvent: false});
        }
        break;
    }
  }
}
