<h1 mat-dialog-title>{{title}}</h1>
<form [formGroup]="formGroup">

<mat-dialog-content>
<!--  <mat-form-field appearance="outline">-->
<!--    <input matInput [matDatepicker]="dataRitiro" formControlName="data_ritiro">-->
<!--    <mat-datepicker-toggle  matSuffix [for]="dataRitiro"></mat-datepicker-toggle>-->
<!--    <mat-datepicker #dataRitiro></mat-datepicker>-->
<!--  </mat-form-field>-->
  <div>
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Data ora</mat-label>
      <input matInput [ngxMatDatetimePicker]="dataRitiro" formControlName="data_ritiro">
      <mat-datepicker-toggle matSuffix [for]="dataRitiro"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #dataRitiro></ngx-mat-datetime-picker>
    </mat-form-field>
  </div>
  <div>
  <app-entity-selector label="Filiale che Accetta" searchField="fil_nome" fxFlex
                       [filters]="[{column: 'gestione', search: 'racc'}]"
                       [required]="true"
                       [paginator]="filialiPaginatorService"
                       [value]="filialeAccetta"
                       [entityStringifier]="filialeStringifier"
                       [disabled]="userService.hasRole('racc_filiale')"
                       (valueChanges)="filialeAccetta = $event"></app-entity-selector>
  </div>

<!--  <div fxLayout="row">-->
<!--    <mat-form-field appearance="outline" fxFlex="50">-->
<!--      <mat-label>Data Ritiro</mat-label>-->
<!--      <input matInput [matDatepicker]="dataRitiro" formControlName="port_dt_acquisto">-->
<!--      <mat-datepicker-toggle matSuffix [for]="dataRitiro"></mat-datepicker-toggle>-->
<!--      <mat-datepicker #dataRitiro></mat-datepicker>-->
<!--    </mat-form-field>-->
<!--  </div>-->

<!--  <div fxLayout="row">-->
<!--    <app-entity-selector fxFlex="50"-->
<!--                         label="Filiale"-->
<!--                         searchField="fil_nome"-->
<!--                         required-->
<!--                         [paginator]="filialePaginator"-->
<!--                         [value]="filialeAccetta"-->
<!--                         [entityStringifier]="filialeStringifier"-->
<!--                         [disabled]="userService.hasRole('racc_filiale')"-->
<!--                         (valueChanges)="filialeAccetta = $event"></app-entity-selector>-->
<!--  </div>-->
</mat-dialog-content>
  </form>
<mat-dialog-actions  fxLayout="row" fxLayoutAlign="end" fxLayoutGap="16px">
  <button mat-flat-button mat-dialog-close>Annulla</button>
  <button mat-raised-button (click)="onConfirmButtonClicked()" color="accent" [disabled]="!isValid">Conferma</button>
</mat-dialog-actions>


