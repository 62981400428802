import {FuseNavigation} from '@fuse/types';

export const navigation: FuseNavigation[] = [
  {
    id: 'registries',
    title: 'Anagrafiche',
    type: 'group',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        icon: 'dashboard',
        url: '/atti/dashboard',
      },
      {
        id: 'filiali',
        title: 'Filiali',
        type: 'item',
        icon: 'business',
        url: '/atti/filiali',
      },
      {
        id: 'users',
        title: 'Utenti',
        type: 'item',
        icon: 'person',
        url: '/atti/users'
      }
    ]
  }
];
