import {FuseNavigation} from '@fuse/types';

export const navigation: FuseNavigation[] = [
  {
    id: 'registries',
    title: 'Anagrafiche',
    type: 'group',
    children: [
      {
        id: 'clienti',
        title: 'Clienti',
        type: 'item',
        icon: 'business_center',
        url: '/clienti',
      },
      {
        id: 'filiali',
        title: 'Filiali',
        type: 'item',
        icon: 'business',
        url: '/filiali',
      },
      {
        id: 'users',
        title: 'Utenti',
        type: 'item',
        icon: 'person',
        url: '/users',
      },
      {
        id: 'portalettere',
        title: 'Portalettere',
        type: 'item',
        icon: 'person',
        url: '/portalettere'
      },
      {
        id: 'operazioni',
        title: 'Operazioni',
        type: 'item',
        icon: 'person',
        url: '/operazioni'
      },
    ]
  }
];
