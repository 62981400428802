import {Injectable} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {FuseConfigService} from '../../../../@fuse/services/config.service';
import {FuseNavigationService} from '../../../../@fuse/components/navigation/navigation.service';
import {System} from '../models/entities/system';
import {navigation as attiNavigation} from '../../atti/navigation';
import {navigation as parcelNavigation} from '../../parcel/navigation';
import {navigation as raccomandateNavigation} from '../../raccomandate/navigation';
import {navigation as sdocNavigation} from '../../sdoc/navigation';
import {FuseNavigation} from '../../../../@fuse/types';
import {UserService} from './user.service';

@Injectable({
  providedIn: 'root'
})
export class SystemsService {
  public selectedSystem: System;
  public navigation: FuseNavigation[] = [];

  constructor(private _fuseConfigService: FuseConfigService,
              private _fuseNavigationService: FuseNavigationService,
              private _router: Router,
              private _userService: UserService) {
    this._fuseNavigationService.register('atti', attiNavigation);
    this._fuseNavigationService.register('parcel', parcelNavigation);
    this._fuseNavigationService.register('raccomandate', raccomandateNavigation);
    this._fuseNavigationService.register('sdoc', sdocNavigation);

    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
      )
      .subscribe((event: NavigationStart) => {
        if (event.url.startsWith('/atti')) {
          this.selectedSystem = System.ATTI;
          this.navigation = attiNavigation;
          this._fuseNavigationService.setCurrentNavigation('atti');
          this._fuseConfigService.config = {colorTheme: 'theme-fulmine-atti'};
        }
        if (event.url.startsWith('/parcel')) {
          this.selectedSystem = System.PARCEL;
          this.navigation = parcelNavigation;
          this._fuseNavigationService.setCurrentNavigation('parcel');
          this._fuseConfigService.config = {colorTheme: 'theme-fulmine-parcel'};
        }
        if (event.url.startsWith('/raccomandate')) {

          this.selectedSystem = System.RACC;
          this.navigation = raccomandateNavigation;
          this._fuseNavigationService.setCurrentNavigation('raccomandate');
          this._fuseConfigService.config = {colorTheme: 'theme-fulmine-raccomandate'};
        }
        if (event.url.startsWith('/sdoc')) {
          this.selectedSystem = System.SDOC;
          this.navigation = sdocNavigation;
          this._fuseNavigationService.setCurrentNavigation('sdoc');
          this._fuseConfigService.config = {colorTheme: 'theme-fulmine-sdoc'};
        }

        if (this._userService.user) {
          for (const navigation of this.navigation) {
            this.checkFuseNavigation(navigation, this.selectedSystem);
          }
        }
      });
  }

  private checkFuseNavigation(fuseNavigation: FuseNavigation, system: System): void {
    if (fuseNavigation.children) {
      for (const child of fuseNavigation.children) {
        this.checkFuseNavigation(child, system);
      }
      if (fuseNavigation.children.every(nav => nav.hidden)) {
        fuseNavigation.hidden = true;
      }
    } else {
      // console.log(`PERMESSO ${System[system].toLowerCase()}_menu_${fuseNavigation.id}`);
      fuseNavigation.hidden = !this._userService.can(`${System[system].toLowerCase()}_menu_${fuseNavigation.id}`);
    }
  }
}
