import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FuseConfigService } from "@fuse/services/config.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { SystemsService } from "../../../fulmine/common/services/systems.service";
import { OAuthService } from "angular-oauth2-oidc";
import { Router } from "@angular/router";
import { UserService } from "../../../fulmine/common/services/user.service";
import { EchoService } from "ngx-laravel-echo";
import { OperazioniUtenteService } from "../../../fulmine/common/services/operazioni-utente.service";
import { NotificationsService } from "../../../fulmine/common/services/notifications.service";

@Component({
  selector: "toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {EchoService} _echoService
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {OAuthService} _oauthService
   * @param {Router} _router
   * @param {SystemsService} systemsService
   * @param {UserService} userService
   * @param {OperazioniUtenteService} operazioniService
   * @param {NotificationsService} notificationsService
   */
  constructor(
    private _echoService: EchoService,
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _oauthService: OAuthService,
    private _router: Router,
    public systemsService: SystemsService,
    public userService: UserService,
    private operazioniService: OperazioniUtenteService,
    private notificationsService: NotificationsService
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === "top";
        this.rightNavbar = settings.layout.navbar.position === "right";
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  resetStorage() {
    sessionStorage.clear();
  }

  public logout(): void {
    // this.systemsService.navigation = [];
    this.notificationsService.logout();
    this.operazioniService.logout();
    this._oauthService.logOut();
    this._echoService.logout();
    this._router.navigate(["/login"]).then((_) => window.location.reload());
  }
}
