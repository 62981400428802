import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { AppInjectorService } from '../../../services/app-injector.service';

@Component({
  selector: 'app-add-files-importer-pdf-dialog-massive',
  templateUrl: './add-files-importer-pdf-dialog.component.html',
  styleUrls: ['./add-files-importer-pdf-dialog.component.scss']
})
export class AddFilesImporterPdfDialogComponentMultiple {
  public files: File[] = [];
  public isLoading = new BehaviorSubject<boolean>(false);
  public maxSize = 0;
  protected _dialog: MatDialog;

  constructor(@Inject(MAT_DIALOG_DATA) public data: Payload,
    private _dialogRef: MatDialogRef<AddFilesImporterPdfDialogComponentMultiple>
  ) {
    this.maxSize = this.data.maxSize ? this.data.maxSize : 30000 * 1024;
    this._dialog = AppInjectorService.injector.get(MatDialog);
  }

  public async onConfirmButtonClicked(): Promise<void> {
    const res = [];
    for (const file of this.files) {
      const formData = new FormData();
      formData.append('files', file);

      res.push(formData);
    }
    
    this._dialogRef.close(res);
  }
}

export interface Payload {
  maxSize: number;
  id_file_du: number;
}
