import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
  {
    id: 'home',
    title: 'Home',
    type: 'group',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        icon: 'dashboard',
        url: '/raccomandate/dashboard',
      }
    ]
  },
  {
    id: 'anagrafiche',
    title: 'Anagrafiche',
    type: 'group',
    children: [
      {
        id: 'operazioni',
        title: 'Operazioni',
        type: 'item',
        icon: 'person',
        url: '/raccomandate/operazioni'
      },
      {
        id: 'utenti',
        title: 'Utenti',
        type: 'item',
        icon: 'person',
        url: '/raccomandate/users'
      },
      {
        id: 'clienti',
        title: 'Clienti',
        type: 'item',
        icon: 'business_center',
        url: '/raccomandate/clienti'
      },
      {
        id: 'filiali',
        title: 'Filiali',
        type: 'item',
        icon: 'business',
        url: '/raccomandate/filiali'
      },
      {
        id: 'portalettere',
        title: 'Portalettere',
        type: 'item',
        icon: 'person',
        url: '/raccomandate/portalettere'
      },
      {
        id: 'tariffepostali',
        title: 'Tariffe postali',
        type: 'item',
        icon: 'euro_symbol',
        url: '/raccomandate/tariffe_postali'
      },
      {
        id: 'royaltycliente',
        title: 'Royalty cliente',
        type: 'item',
        icon: 'euro_symbol',
        url: '/raccomandate/royalties'
      },
      {
        id: 'tempigiacenza',
        title: 'Tempi giacenza',
        type: 'item',
        icon: 'timer',
        url: '/raccomandate/tempi_giacenze'
      }

    ]
  },
  {
    id: 'gestione',
    title: 'Gestione',
    type: 'group',
    children: [
      {
        id: 'lotti',
        title: 'Lotti',
        type: 'item',
        icon: 'widgets',
        url: '/raccomandate/lotti'
      },
      {
        id: 'lotti_pa',
        title: 'Lotti PAGOPA',
        type: 'item',
        icon: 'widgets',
        url: '/raccomandate/lotti_pa'
      },
      // TODO check permessi users
      {
        id: 'accettazione_pa',
        title: 'Accettazione PAGOPA',
        type: 'item',
        icon: 'widgets',
        url: '/raccomandate/accettazione_pa'
      },
      // TODO check permessi users
      {
        id: 'indagine_portalettere_pa',
        title: 'Indagine Portalettere PAGOPA',
        type: 'item',
        icon: 'widgets',
        url: '/raccomandate/raccomandate_pa'
      },
      {
        id: 'indagine_portalettere_pa_multi',
        title: 'Indagine Port PAGOPA Multi',
        type: 'item',
        icon: 'widgets',
        url: '/raccomandate/raccomandate_pa_multi'
      },
      {
        id: 'log_pa',
        title: 'Log PAGOPA',
        type: 'item',
        icon: 'widgets',
        url: '/raccomandate/log_pa'
      },
      {
        id: 'reinvii_pa',
        title: 'Reinvii PAGOPA',
        type: 'item',
        icon: 'widgets',
        url: '/raccomandate/reinvii_pa'
      },
      {
        id: 'dispacci',
        title: 'Dispacci',
        type: 'item',
        icon: 'directions_transit',
        url: '/raccomandate/dispacci'
      },
      {
        id: 'affidi',
        title: 'Affidi portalettere',
        type: 'item',
        icon: 'add_to_home_screen',
        url: '/raccomandate/affidi'
      },
      {
        id: 'acquisizione',
        title: 'Acquisizione dati palmare',
        type: 'item',
        icon: 'cloud_download',
        url: '/raccomandate/acquisizioni_palmari'
      },
      {
        id: 'problemiacquisizione',
        title: 'Problemi acquisizione palmari',
        type: 'item',
        icon: 'error',
        url: '/raccomandate/problemi_acquisizioni'
      },
      {
        id: 'esitifilialinonacquisibili',
        title: 'Esiti filiali non acquisibili',
        type: 'item',
        icon: 'error',
        url: '/raccomandate/esiti_filiali_non_acquisibili'
      },
      {
        id: 'setfatturazionerete',
        // title: 'Fatturazione Rete',
        title: 'Popola Filiale Fatturazione Mese',
        type: 'item',
        icon: 'list_alt',
        url: '/raccomandate/elabora_fatturazione_rete'
      },
    ]
  },
  {
    id: 'fatturazione',
    title: 'operazioni',
    type: 'group',
    children: [
      {
        id: 'fatturazione',
        title: 'Fatturazione Fulmine',
        type: 'item',
        icon: 'picture_as_pdf',
        url: '/raccomandate/fatturazione-fulmine'
      }
    ]
  },
  {
    id: 'ricerche',
    title: 'ricerche',
    type: 'group',
    children: [
      {
        id: 'ricerche',
        title: 'Ricerche',
        type: 'item',
        icon: 'search',
        url: '/raccomandate/lotti_raccomandate'
      },
    ]
  },
  {
    id: 'tracciature',
    title: 'Tracciature',
    type: 'group',
    children: [
      {
        id: 'confermaconsegna',
        title: 'Conferma Consegna',
        type: 'item',
        icon: 'edit',
        url: '/raccomandate/tracciature/conferma_consegna'
      },
      {
        id: 'tracciaturaresi',
        title: 'Tracciatura Resi',
        type: 'item',
        icon: 'edit',
        url: '/raccomandate/tracciature/resi'
      },
      {
        id: 'consegnaufficio',
        title: 'Consegna in Ufficio',
        type: 'item',
        icon: 'edit',
        url: '/raccomandate/tracciature/consegna_in_ufficio'
      },
      {
        id: 'tracciaturacompiutagiacenze',
        title: 'Tracciatura Compiute Giacenze',
        type: 'item',
        icon: 'edit',
        url: '/raccomandate/tracciature/compiute_giacenze'
      },
      {
        id: 'tracciaturaresimittente',
        title: 'Tracciatura Resi Mittente',
        type: 'item',
        icon: 'edit',
        url: '/raccomandate/tracciature/resi_mittente'
      },
      {
        id: 'tracciaturasmarrimentoresi',
        title: 'Tracciatura Smarrimento Resi',
        type: 'item',
        icon: 'edit',
        url: '/raccomandate/tracciature/smarrimento_resi'
      },
      {
        id: 'tracciaturaesitiposte',
        title: 'Tracciatura Esiti Poste',
        type: 'item',
        icon: 'edit',
        url: '/raccomandate/tracciature/esiti_poste'
      },
      {
        id: 'ritornoricevutearalmittente',
        title: 'Ritorno Ricevute AR al Mittente',
        type: 'item',
        icon: 'edit',
        url: '/raccomandate/tracciature/ritorno_ar_mittente'
      },
    ]
  },
  {
    id: 'esiti',
    title: 'Esiti',
    type: 'group',
    children: [
      {
        id: 'postalizzazione',
        title: 'Postalizzazione',
        type: 'item',
        icon: 'mail',
        url: '/raccomandate/postalizzazione'
      },
    ]
  },
  {
    id: 'report',
    title: 'Report',
    type: 'group',
    children: [
      {
        id: 'reportlasciatiavvisi',
        title: 'Lasciati avvisi',
        type: 'item',
        icon: 'picture_as_pdf',
        url: '/raccomandate/lasciati_avvisi'
      },
      {
        id: 'reportresimittente',
        title: 'Resi mittente',
        type: 'item',
        icon: 'picture_as_pdf',
        url: '/raccomandate/resi_mittente'
      },
      {
        id: 'reportricevuteresemittente',
        title: 'Ricevute rese al mittente',
        type: 'item',
        icon: 'picture_as_pdf',
        url: '/raccomandate/ricevute_resi_mittente'
      },
      {
        id: 'reportesitiposte',
        title: 'Report esiti poste',
        type: 'item',
        icon: 'picture_as_pdf',
        url: '/raccomandate/report_esiti_poste'
      },
      {
        id: 'reportsollecitifiliali',
        title: 'Solleciti filiali',
        type: 'item',
        icon: 'picture_as_pdf',
        url: '/raccomandate/report_solleciti_filiali'
      },
      {
        id: 'reportclienti',
        title: 'Cliente',
        type: 'item',
        icon: 'picture_as_pdf',
        url: '/raccomandate/report_cliente'
      },
      {
        id: 'reportreportclienti',
        title: 'Report Clienti',
        type: 'item',
        icon: 'picture_as_pdf',
        url: '/raccomandate/report_report_clienti'
      },
      {
        id: 'reportenel',
        title: 'Enel',
        type: 'item',
        icon: 'picture_as_pdf',
        url: '/raccomandate/report_enel'
      },
      // {
      //   /* non usato */
      //   id: 'reportfatturazione',
      //   title: 'Fatturazione',
      //   type: 'item',
      //   icon: 'picture_as_pdf',
      //   url: '/test'
      // },
      {
        id: 'reportpostalizzazione',
        title: 'Postalizzazione',
        type: 'item',
        icon: 'picture_as_pdf',
        url: '/raccomandate/report_postalizzazione'
      },
      {
        id: 'getfatturazionerete',
        title: 'Fatturazione Filiali',
        type: 'item',
        icon: 'picture_as_pdf',
        url: '/raccomandate/fatturazione_rete'
      },
      {
        id: 'reportmodulocontrollo',
        title: 'Modulo controllo',
        type: 'item',
        icon: 'picture_as_pdf',
        url: '/raccomandate/modulo_controllo'
      },
      {
        id: 'reportsla',
        title: 'Report SLA',
        type: 'item',
        icon: 'picture_as_pdf',
        url: '/raccomandate/report_sla'
      },
    ]
  },
  {
    id: 'utility',
    title: 'Utility',
    type: 'group',
    children: [
      {
        id: 'export_portalettere',
        title: 'Estrai elenco portalettere',
        type: 'item',
        icon: 'timelapse',
        url: '/raccomandate/export_portalettere'
      },
      {
        id: 'geolocalizzazione',
        title: 'Geolocalizzazione',
        type: 'item',
        icon: 'location_on',
        url: '/raccomandate/geolocalizzazione'
      },
      {
        id: 'uploadbustascansionepdf',
        title: 'Upload scansione busta Pdf',
        type: 'item',
        icon: 'cloud_upload',
        url: '/raccomandate/upload_scansione_busta'
      },
      {
        id: 'elaborascansionericevute',
        title: 'Elabora scansioni ricevute',
        type: 'item',
        icon: 'timelapse',
        url: '/raccomandate/elabora_scansioni_ar'
      },
      {
        id: 'creaelaborazionescansione',
        title: 'Crea elaborazione scansione',
        type: 'item',
        icon: 'timelapse',
        url: '/raccomandate/crea_elaborazione_scansione'
      },
      {
        id: 'esitibarcode',
        title: 'Esiti barcode',
        type: 'item',
        icon: 'apps',
        url: '/raccomandate/esiti_barcode'
      },
      {
        id: 'estrairicevutebarcode',
        title: 'Estrai ricevute barcode',
        type: 'item',
        icon: 'apps',
        url: '/raccomandate/esiti_ricevute'
      },
      {
        id: 'stamparicevutebarcode',
        title: 'Stampa ricevute barcode',
        type: 'item',
        icon: 'apps',
        url: '/raccomandate/stampa_ricevute'
      },
      {
        id: 'abilitfatturazionefiliali',
        title: 'Abilita fatturazione filiali',
        type: 'item',
        icon: 'euro_symbol',
        url: '/raccomandate/abilita_fatturazione_filiali'
      },
      {
        id: 'aggiungitogliericevutaar',
        title: 'Aggiungi/Togli Ricevuta AR',
        type: 'item',
        icon: 'note_add',
        url: '/raccomandate/aggiungi_rimuovi_ric_ar'
      },
      {
        id: 'multiaccettadispacciopalermo',
        title: 'Multi accetta dispacci Palermo',
        type: 'item',
        icon: 'directions_transit',
        url: '/raccomandate/multi_accetta_dispacci_palermo'
      },
      {
        id: 'trasmettiackenel',
        title: 'Trasmetti ack enel',
        type: 'item',
        icon: 'cloud_upload',
        url: '/raccomandate/trasmetti_ack_enel'
      },
      {
        id: 'coperturacap',
        title: 'Copertura Cap',
        type: 'item',
        icon: 'edit_location',
        url: '/raccomandate/copertura_cap'
      },
      {
        id: 'splittafilestampatore',
        title: 'Splitta file stampatore',
        type: 'item',
        icon: 'insert_drive_file',
        url: '/raccomandate/splitta_file_stampatore'
      },
      {
        id: 'query',
        title: 'Query',
        type: 'item',
        icon: 'search',
        url: '/raccomandate/query'
      },
      {
        id: 'correggirecord',
        title: 'Correggi record',
        type: 'item',
        icon: 'edit',
        url: '/raccomandate/correggi_record'
      },
      {
        id: 'lasciatiavvisidigitali',
        title: 'Lasciati avvisi digitali',
        type: 'item',
        icon: 'apps',
        url: '/raccomandate/lasciati_avvisi_digitali'
      },
      {
        id: 'sistemaesitiitalposte',
        title: 'Sistema Esiti Italposte',
        type: 'item',
        icon: 'apps',
        url: '/raccomandate/correzioni_italposte'
      },
    ]
  }
];
