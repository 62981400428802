import {FuseNavigation} from '@fuse/types';

export const navigation: FuseNavigation[] = [
  {
    id: 'home',
    title: 'Home',
    type: 'group',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        icon: 'dashboard',
        url: '/sdoc/dashboard',
      }
    ]
  },
  {
    id: 'anagrafiche',
    title: 'Anagrafiche',
    type: 'group',
    children: [
      {
        id: 'operazioni',
        title: 'Operazioni',
        type: 'item',
        icon: 'person',
        url: '/sdoc/operazioni'
      },
      {
        id: 'utenti',
        title: 'Utenti',
        type: 'item',
        icon: 'person',
        url: '/sdoc/users'
      },
      {
        id: 'clienti',
        title: 'Clienti',
        type: 'item',
        icon: 'business_center',
        url: '/sdoc/clienti'
      },
      {
        id: 'filiali',
        title: 'Filiali',
        type: 'item',
        icon: 'business',
        url: '/sdoc/filiali'
      },
      {
        id: 'portalettere',
        title: 'Portalettere',
        type: 'item',
        icon: 'person',
        url: '/sdoc/portalettere'
      },
      {
        id: 'tariffepostali',
        title: 'Tariffe postali',
        type: 'item',
        icon: 'euro_symbol',
        url: '/sdoc/tariffe-postali'
      },
      {
        id: 'localita',
        title: 'Localita',
        type: 'item',
        icon: 'location_on',
        url: '/sdoc/localita'
      },
      {
        id: 'coperturacap',
        title: 'Copertura cap',
        type: 'item',
        icon: 'edit_location',
        url: '/sdoc/aggiornamento-copertura-cap'
      },
      {
        id: 'caplocalitafiliali',
        title: 'Gestione cap località delle filiali',
        type: 'item',
        icon: 'edit_location',
        url: '/sdoc/aggiornamento-cap-filiali'
      },
      {
        id: 'capareepostali',
        title: 'Legami cap e aree postali',
        type: 'item',
        icon: 'edit_location',
        url: '/sdoc/cap-aree'
      },
      {
        id: 'regoleimportazione',
        title: 'Regole importazione',
        type: 'item',
        icon: 'settings',
        url: '/sdoc/regole-importazione'
      },
      {
        id: 'normalizzazioneamapupload',
        title: 'Aggiorna Normalizzazione Amap',
        type: 'item',
        icon: 'picture_as_pdf',
        url: '/sdoc/normalizzazione_upload'
      },
    ]
  },
  {
    id: 'controlli',
    title: 'Controlli',
    type: 'group',
    children: [
      {
        id: 'lotti',
        title: 'Lotti',
        type: 'item',
        icon: 'widgets',
        url: '/sdoc/lotti'
      },
      {
        id: 'controlloinsmistamento',
        title: 'Controllo stato letture',
        type: 'item',
        icon: 'date_range',
        url: '/sdoc/controllo-stato-letture'
      },
      {
        id: 'situazionelotticlienti',
        title: 'Controllo lotti non importate',
        type: 'item',
        icon: 'date_range',
        url: '/sdoc/situazione-lotti-clienti'
      },
    ]
  },
  {
    id: 'ricerche',
    title: 'Ricerche',
    type: 'group',
    children: [
      {
        id: 'ricerche',
        title: 'Multi Ricerca',
        type: 'item',
        icon: 'search',
        url: '/sdoc/letture'
      },
    ]
  },
  {
    id: 'operazioni',
    title: 'Operazioni',
    type: 'group',
    children: [
      {
        id: 'importazione',
        title: 'Importa file da ente/stampatore',
        type: 'item',
        icon: 'cloud_upload',
        url: '/sdoc/importazione'
      },
      {
        id: 'spostafile',
        title: 'Cancella/sposta file di un lotto',
        type: 'item',
        icon: 'clear',
        url: '/sdoc/file-lotto'
      },
      {
        id: 'smistafiliali',
        title: 'Affido lettere (smista alle filiali)',
        type: 'item',
        icon: 'add_to_home_screen',
        url: '/sdoc/lotto-smistamento'
      },
      {
        id: 'acquisizione',
        title: 'Acquisizione file terminali',
        type: 'item',
        icon: 'cloud_download',
        url: '/sdoc/acquisizione-file-terminali'
      },
      {
        id: 'erroriacquisizione',
        title: 'Errori acquisizione',
        type: 'item',
        icon: 'error_outline',
        url: '/sdoc/errori-acquisizione'
      },
      {
        id: 'multiedita_barcode',
        title: 'Tracciatura barcode',
        type: 'item',
        icon: 'insert_drive_file',
        url: '/sdoc/multiedita-barcode'
      },
      {
        id: 'convertifileligra',
        title: 'Carica esiti Ligra/Uniposta',
        type: 'item',
        icon: 'insert_drive_file',
        url: '/sdoc/carica-file-ligra-uniposta'
      },
      {
        id: 'postalizzazioneautomatica',
        title: 'Postalizzazione automatica',
        type: 'item',
        icon: 'check',
        url: '/sdoc/postalizzazione-automatica'
      },
      // {
      //   id: 'geolocalizzazione',
      //   title: 'Geolocalizzazione',
      //   type: 'item',
      //   icon: 'location_on',
      //   url: '/sdoc/geolocalizzazione'
      // },
      {
        id: 'creafileaccettazione',
        title: 'Crea file accettazione',
        type: 'item',
        icon: 'insert_drive_file',
        url: '/sdoc/crea-file-accettazione'
      },
      {
        id: 'popolaletdittafatt',
        title: 'Popola filiale fatturata mese',
        type: 'item',
        icon: 'cloud_download',
        url: '/sdoc/popola-filiale-fatturata'
      },
      {
        id: 'tracciaresiposte',
        title: 'Tracciatura resi poste',
        type: 'item',
        icon: 'location_on',
        url: '/sdoc/tracciatura-resi'
      },
      /*
      {
        id: 'accettazioneparcelinhub',
        title: 'Accettazione parcel in hub',
        type: 'item',
        icon: 'check',
        url: '/sdoc/accettazione-parcel-in-hub'
      },
      */
      {
        id: 'fatturazione',
        title: 'Fatturazione Fulmine',
        type: 'item',
        icon: 'picture_as_pdf',
        url: '/sdoc/fatturazione-fulmine'
      },
      {
        id: 'rintraccia',
        title: 'Rintraccia',
        type: 'item',
        icon: 'insert_drive_file',
        url: '/sdoc/rintraccia'
      },
    ]
  },
  {
    id: 'stampe ed estrazioni',
    title: 'Stampe ed estrazioni',
    type: 'group',
    children: [
      {
        id: 'grafici',
        title: 'Grafici',
        type: 'item',
        icon: 'insert_chart',
        url: '/sdoc/grafici'
      },
      {
        id: 'loginviiifiliali',
        title: 'Log Invii Filiali',
        type: 'item',
        icon: 'picture_as_pdf',
        url: '/sdoc/log-invii-filiali'
      },
      {
        id: 'distintapostresi',
        title: 'Estrazione distinta postalizzati e resi',
        type: 'item',
        icon: 'picture_as_pdf',
        url: '/sdoc/estrazione-distinta-postalizzati'
      },
      {
        id: 'stampabarcode',
        title: 'Stampa barcode',
        type: 'item',
        icon: 'picture_as_pdf',
        url: '/sdoc/stampa-barcode'
      },
      {
        id: 'exportresiposte',
        title: 'Esportazione resi poste',
        type: 'item',
        icon: 'picture_as_pdf',
        url: '/sdoc/estrazione-resi'
      },
      {
        id: 'affidiclienti',
        title: 'Affidi clienti',
        type: 'item',
        icon: 'picture_as_pdf',
        url: '/sdoc/report-affidi-clienti'
      },
      {
        id: 'affidifiliali',
        title: 'Affidi filiali',
        type: 'item',
        icon: 'picture_as_pdf',
        url: '/sdoc/report-affidi-filiali'
      },
      {
        id: 'reportclienti',
        title: 'Report clienti',
        type: 'item',
        icon: 'picture_as_pdf',
        url: '/sdoc/report-clienti'
      },
      {
        id: 'reportfiliali',
        title: 'Report filiali',
        type: 'item',
        icon: 'picture_as_pdf',
        url: '/sdoc/report-filiali'
      },
      {
        id: 'reportresi',
        title: 'Report resi',
        type: 'item',
        icon: 'picture_as_pdf',
        url: '/sdoc/report-resi'
      },
      {
        id: 'esportazioneesiti',
        title: 'Esportazione Esiti',
        type: 'item',
        icon: 'picture_as_pdf',
        url: '/sdoc/esportazione-esiti'
      },
      {
        id: 'normalizzazioneamapview',
        title: 'Normalizzazione',
        type: 'item',
        icon: 'picture_as_pdf',
        url: '/sdoc/normalizzazione'
      },
      {
        id: 'report_lotti_aqp',
        title: 'Report AQP',
        type: 'item',
        icon: 'picture_as_pdf',
        url: '/sdoc/aqp'
      },
    ]
  }
];
