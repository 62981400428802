import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Paginator, Query} from '../../../models/paginator';
import {MatDialog} from '@angular/material/dialog';
import {MultiEntitySelectorDialogComponent} from '../../dialogs/multi-entity-selector-dialog/multi-entity-selector-dialog.component';
import {TableColumn} from '../../../models/table-column';
import {Sort} from '@angular/material/sort';

@Component({
  selector: 'app-multi-entity-selector',
  templateUrl: './multi-entity-selector.component.html',
  styleUrls: ['./multi-entity-selector.component.scss']
})
export class MultiEntitySelectorComponent<T> {
  @Input()
  public dialogColumns: TableColumn[];

  @Input()
  public dialogDisplayedColumns: string[];

  @Input()
  public disabled = false;

  @Input()
  public entityStringifier: (entity: T) => string;

  @Input()
  public label: string;

  @Input()
  public paginator: Paginator<T>;

  @Input()
  public required = false;

  @Input()
  public searchFields: string[];

  @Input()
  public sort: Sort = null;

  @Input()
  public multiSelect = true;

  @Input()
  public selectedEntities: T[] = [];

  @Input()
  public filters: Query[];

  @Input()
  public showSelectAll = false;

  @Output()
  public selectedEntitiesChange = new EventEmitter<T[]>();

  constructor(private dialog: MatDialog) {
  }

  public async onAddButtonClicked(): Promise<void> {
    const result = await this.dialog.open(MultiEntitySelectorDialogComponent, {
      data: {
        columns: this.dialogColumns,
        displayedColumns: this.dialogDisplayedColumns,
        paginator: this.paginator,
        multiSelect: this.multiSelect,
        searchFields: this.searchFields,
        selectedEntities: this.selectedEntities,
        filters: this.filters,
        showSelectAll: this.showSelectAll,
        sort: this.sort ? this.sort : null,
      }
    }).afterClosed().toPromise();
    if (result) {
      this.selectedEntities.push(result);
      this.selectedEntitiesChange.emit(result);
    }
  }

  public onEntityRemoved(index: number): void {
    this.selectedEntities.splice(index, 1);
    this.selectedEntitiesChange.emit(this.selectedEntities);
  }
}
