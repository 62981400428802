import { Component, OnInit } from '@angular/core';
import { User } from '../../../models/entities/user';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Cliente } from '../../../models/entities/cliente';
import { UserService } from '../../../services/user.service';
import { BaseComponent } from '../base/base.component';
import { RistampaService } from 'app/fulmine/common/services/api/ristampa-api.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-ristampa',
  templateUrl: './ristampa.component.html',
  styleUrls: ['./ristampa.component.scss']
})
export class RistampaComponent extends BaseComponent implements OnInit {

  public user: User;
  public cliente: Cliente;
  public formGroup: FormGroup;

  constructor(
    private _formBuilder: FormBuilder,
    public _userService: UserService,
    private _router: Router,
    private _ristampa: RistampaService,
    private _location: Location,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.user = this._userService.user;

    this.formGroup = this._formBuilder.group({
      name: [null],
      barcode: [null, Validators.required],
      cap: [null, Validators.required],
      cliente_id: [null],
      cliente_nome: [null],
      destinatario: [null, Validators.required],
      doc_id: [null],
      indirizzo: [null, Validators.required],
      localita: [null, Validators.required],
      provincia: [null, Validators.required],
      email: [null, [Validators.required]],
      note: [null]
    });


    this.formGroup.controls.name.disable();
    const url = this._router.url.split("/");
    const res = await this._ristampa.getInfos(url[3], url[1]);

    this.formGroup.patchValue(res[0]);
    this.formGroup.controls.name.setValue(this.user.name);
    this.formGroup.updateValueAndValidity();
  }

  async save() {
    console.log(this.formGroup.controls.email);
    
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;}
    this._fuseProgressBarService.show();
    const url = this._router.url.split("/");
    const res = await this._ristampa.send({
      idDoc: this.formGroup.value.doc_id,
      type: url[1] === "raccomandate" ? "racc" : "sdoc",
      email: this.formGroup.value.email,
      note: this.formGroup.value.note,
      isUser: this.user.id
    }) as any;

    this._fuseProgressBarService.hide();
    if (res.success == true) this.back();
  }

  public back(): void {
    this._location.back();
  }
}
