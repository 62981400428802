<button mat-icon-button [matMenuTriggerFor]="operazioniMenu">
  <mat-icon class="main-icon"
            matBadge="{{operazioniService.workingOperations}}"
            [matBadgeHidden]="operazioniService.workingOperations === 0">access_time</mat-icon>
</button>

<mat-menu #operazioniMenu xPosition="before">
  <h4 *ngIf="!(operazioniService.isLoading | async) && operazioniService.operazioni.length === 0"
      class="px-24">
    Nessuna operazione disponibile.
  </h4>

  <mat-list infiniteScroll
            [scrollWindow]="false"
            (scrolled)="operazioniService.loadNext()">
    <a mat-list-item *ngFor="let operazione of operazioniService.operazioni"
                     class="selectable-row"
                     matRipple
                     href="#"
                     [routerLink]="[operazione.gestione, 'operazioni', operazione.id]">
      <div class="operazione" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
        <mat-icon *ngIf="operazione.stato === 'completato'" class="success" matTooltip="completato">check_circle</mat-icon>
        <mat-icon *ngIf="operazione.stato === 'in corso'" matTooltip="in corso">sync</mat-icon>
        <mat-icon *ngIf="operazione.stato === 'errore'" class="error" matTooltip="errore">error_outline</mat-icon>
        <mat-icon *ngIf="operazione.stato === 'attenzionato'" class="warning" matTooltip="completato con avvisi">warning_amber</mat-icon>

        <h3 matLine
            class="title"
            [class.atti-accent-foreground]="operazione.gestione === 'atti'"
            [class.parcel-accent-foreground]="operazione.gestione === 'parcel'"
            [class.raccomandate-accent-foreground]="operazione.gestione === 'racc'"
            [class.sdoc-accent-foreground]="operazione.gestione === 'sdoc'">
          {{operazione.titolo}}
        </h3>
      </div>
    </a>

    <mat-list-item *ngIf="operazioniService.isLoading | async">
      <mat-progress-spinner color="accent" diameter="24" mode="indeterminate"></mat-progress-spinner>
    </mat-list-item>
  </mat-list>
</mat-menu>
