import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {OAuthService} from 'angular-oauth2-oidc';
import {UserService} from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private _oauthService: OAuthService,
              private _router: Router,
              private _userService: UserService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this._oauthService.hasValidAccessToken()) {
      this._router.navigate(['/login'], {queryParams: {redirectTo: state.url}});
      return false;
    }
    if (!this._userService.user) {
      this._router.navigate(['/splash'], {queryParams: {redirectTo: state.url}});
      return false;
    }
    return true;
  }
}
