<div fxLayout="column" fxLayout.gt-md="row">
  <ng-container>
    <mat-form-field appearance="outline" fxFlex ngClass.gt-md="pr-8">
      <mat-label>Regione</mat-label>
      <mat-select [formControl]="regioneSelected">
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let regione of regioniList" [value]="regione.id">{{regione.nome}}</mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-container>
    <mat-form-field appearance="outline" fxFlex ngClass.gt-md="pr-8" >
      <mat-label>Capoarea</mat-label>
      <mat-select [formControl]="capoareaSelected" >
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let madre of capoareaList" [value]="madre.fil_id">{{madre.fil_nome}}</mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
</div>
<div fxLayout="column" fxLayout.gt-md="row" *ngIf="capoareaSelected.value">
  <ng-container fxLayoutAlign="end"  appearance="outline" fxFlex ngClass.gt-md="pr-8">
    <mat-checkbox fxFlex class="m-16" [formControl]="madreH">Hub
    </mat-checkbox>
    <mat-checkbox fxFlex class="m-16" [formControl]="madreC">Circuito
    </mat-checkbox>
    <mat-checkbox fxFlex class="m-16" [formControl]="madreD">Distribuzione
    </mat-checkbox>
  </ng-container>
</div>

<div fxLayout="row" fxLayoutAlign="space-between center">

  <mat-form-field mat-dialog-title fxFlex appearance="outline">
    <input matInput placeholder="nome filiale…" [formControl]="nomeFiliale">
    <mat-icon *ngIf="!nomeFiliale.value" matSuffix>search</mat-icon>
    <button *ngIf="nomeFiliale.value"
            mat-icon-button
            matSuffix
            (click)="nomeFiliale.setValue('')">
      <mat-icon>clear</mat-icon>
    </button>
  </mat-form-field>
  <section fxFlex style="text-align:right">
    <button type="button" align="right" mat-raised-button
            (click)="filtra()" color="primary">
      Filtra
    </button>
  </section>
</div>

<div mat-dialog-content fxLayout="column">
  <mat-progress-bar *ngIf="filialiPaginator.isLoading | async" color="accent" mode="indeterminate"></mat-progress-bar>

  <mat-checkbox fxFlex class="m-16" *ngIf="filialiFiltrate?.length>0" [checked]="statoSelezionaTutti"
                (change)="selezionaDeselezionaTutti()">seleziona\deseleziona tutti
  </mat-checkbox>

  <mat-table class="main-table" [dataSource]="filialiFiltrate">
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let entity">
        <mat-checkbox [checked]="selection?.isSelected(entity)"
                      (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(entity) : null">
        </mat-checkbox>
      </mat-cell>
    </ng-container>

    <ng-container *ngFor="let column of filialeColumnDefinitions" [matColumnDef]="column.column">
      <mat-header-cell *matHeaderCellDef>{{column.label}}</mat-header-cell>
      <mat-cell *matCellDef="let entity">
        <span *ngIf="column.type === 0">{{entity[column.column]}}</span>
        <span *ngIf="column.type === 1">{{entity[column.column] | moment}}</span>
        <mat-checkbox *ngIf="column.type === 2" [checked]="entity[column.column]"></mat-checkbox>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="['select'].concat(filialeDefaultDisplayedColumns); sticky: true;"></mat-header-row>
    <mat-row *matRowDef="let entity; columns: ['select'].concat(filialeDefaultDisplayedColumns);"
             class="selectable-row"
             (click)="selection.toggle(entity)"></mat-row>
  </mat-table>

  <!--
  <app-paginator [paginator]="dittePaginator"></app-paginator>
  //-->
</div>

<mat-dialog-actions fxLayout="row"  fxLayoutAlign="end" fxLayoutGap="16px">
  <button mat-flat-button mat-dialog-close>Annulla</button>
  <button mat-raised-button color="accent" (click)="onConfirmButtonClicked()">Conferma</button>

</mat-dialog-actions>

