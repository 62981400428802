import {Injectable} from '@angular/core';
import {Paginator} from '../../models/paginator';
import {Filiale} from '../../models/entities/filiale';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FilialiPaginatorService extends Paginator<Filiale> {

  constructor(http: HttpClient) {
    super(http, `${environment.baseUrl}api/filiali`);
  }
}
