import {Injectable} from '@angular/core';
import {Notification} from '../models/entities/notification';
import {NotificationsPaginatorService} from './paginators/notifications-paginator.service';
import {EchoService} from 'ngx-laravel-echo';
import {OAuthService} from 'angular-oauth2-oidc';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {UserService} from './user.service';
import {NotificationsApiService} from './api/notifications-api.service';
import {BehaviorSubject, combineLatest} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  public notifications: Notification[] = [];
  public unreadNotifications = 0;

  public get isLoading(): BehaviorSubject<boolean> {
    return this._notificationsPaginator.isLoading;
  }

  constructor(private _echoService: EchoService,
              private _notificationsApiService: NotificationsApiService,
              private _notificationsPaginator: NotificationsPaginatorService,
              private _oauthService: OAuthService,
              private _router: Router,
              private _toastr: ToastrService,
              private _userService: UserService) {
  }

  public async loadNext(): Promise<void> {
    const notifications = (await this._notificationsPaginator.loadNext())?.data ?? [];
    for (const notification of notifications) {
      if (!this.notifications.some(n => n.id === notification.id)) {
        this.notifications.push(notification);
      }
    }
    this.calculateUnreadNotifications();
  }

  public async openNotification(notification: Notification): Promise<void> {
    if (notification.link) {
      this._router.navigate([notification.link]);
    }
    const response = await this._notificationsApiService.markAsRead(notification.id);
    if (response) {
      this.notifications.splice(this.notifications.indexOf(notification), 1, response.data);
      this.calculateUnreadNotifications();
    }
  }

  public login(): void {
    this.initNotifications();
  }

  public logout(): void {
    this.notifications = [];
  }

  private calculateUnreadNotifications(): void {
    this.unreadNotifications = this.notifications.filter(n => n.read_at === null).length;
  }

  private showToast(notification: Notification): void {
    this._toastr.info(notification.descrizione, notification.titolo).onTap.toPromise().then(() => {
      this.openNotification(notification);
    });
  }

  private initNotifications(): void {
    this._echoService.notification('*', `user.${this._userService.user.id}`)
      .subscribe(notification => {
        this.notifications.splice(0, 0, notification);
        this.calculateUnreadNotifications();
        this.showToast(notification);
      });

    this.loadNext();
  }
}
