<div class="page-layout simple fullwidth">

  <!-- HEADER -->
  <div class="header px-64 py-24" fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="m-0">CAMBIO PASSWORD</h2>

    <button mat-raised-button class="action-button"
            color="accent" (click)="onSaveButtonClicked()"
            [disabled]="!formGroup || formGroup.invalid || (userService.isLoading | async)">
      <mat-icon>save</mat-icon>
      SALVA
    </button>
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <form *ngIf="formGroup" class="content px-64 py-24" fusePerfectScrollbar [formGroup]="formGroup" fxLayout="column">
    <div class="fuse-card auto-width p-24" fxLayout.gt-md="row" fxLayoutGap.gt-md="16px">
      <mat-form-field appearance="outline" fxFlex>
        <mat-label>Password</mat-label>
        <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="password" maxlength="30">
        <mat-icon style="cursor: pointer" matSuffix
                  (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        <mat-error *ngIf="formGroup.controls['password'].hasError('required')">Questo campo è obbligatorio.</mat-error>
        <mat-error *ngIf="formGroup.controls['password'].hasError('pattern')">La password deve avere una lunghezza compresa tra 8 e 30 caratteri, e contenere almeno una lettera maiuscola, una minuscola, un numero e un carattere speciale.</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" fxFlex>
        <mat-label>Conferma Password</mat-label>
        <input matInput [type]="hideConfirmPassword ? 'password' : 'text'" formControlName="password_confirmation" maxlength="30">
        <mat-icon style="cursor: pointer" matSuffix
                  (click)="hideConfirmPassword = !hideConfirmPassword">{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        <mat-error *ngIf="formGroup.controls['password_confirmation'].hasError('required')">Questo campo è obbligatorio.</mat-error>
        <mat-error *ngIf="formGroup.controls['password_confirmation'].hasError('NoPasswordMatch')">La Password e la Conferma Password non corrispondono</mat-error>
      </mat-form-field>
    </div>

  </form>
  <!-- / CONTENT -->

</div>
