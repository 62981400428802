import {Component, Inject} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-datepicker-dialog',
  templateUrl: './datepicker-dialog.component.html',
  styleUrls: ['./datepicker-dialog.component.scss']
})
export class DatepickerDialogComponent {
  public formControl = new FormControl();
  title: string;

  constructor(private _dialogRef: MatDialogRef<DatepickerDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Payload) {
    if (data && data.title) {
      this.title = data.title;
    } else {
      this.title = 'Seleziona una data';
    }
  }

  public onConfirmButtonClicked(): void {
    if (!this.formControl.value) {
      return;
    }

    this._dialogRef.close(this.formControl.value);
  }
}

export interface Payload {
  title: string;
}
