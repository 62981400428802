<div class="page-layout simple right-sidebar inner-scroll">

  <!-- SIDEBAR -->
  <fuse-sidebar class="sidebar filters-sidebar" name="filters-sidebar" position="right">

    <!-- SIDEBAR CONTENT -->
    <div class="content p-24" fusePerfectScrollbar>
      <app-filters [filters]="filters" (filtersChanged)="onFiltersChanged($event)"></app-filters>
    </div>
    <!-- / SIDEBAR CONTENT -->

  </fuse-sidebar>
  <!-- / SIDEBAR -->

  <!-- CENTER -->
  <div class="center" fusePerfectScrollbar>

    <!-- HEADER -->
    <div class="header px-64 py-24" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="m-0">ANAGRAFICA FILIALI</h2>

      <div fxLayout="row" fxLayoutGap="16px">
        <button mat-raised-button class="action-button" color="accent"
                [disabled]="(filialiPaginator.isLoading | async)"
                (click)="toggleSidebar('filters-sidebar')">
          <mat-icon>filter_list</mat-icon>
          FILTRA
        </button>

        <a mat-raised-button class="action-button" color="accent" href="#" routerLink="new"
           [disabled]="(filialiPaginator.isLoading | async)">
          <mat-icon>add</mat-icon>
          NUOVA FILIALE
        </a>
      </div>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content" fxLayout="column">
      <mat-table class="main-table mx-64 my-24 px-24 pt-10 pb-24"
                 [dataSource]="filialiPaginator"
                 matSort
                 matSortActive="fil_id"
                 matSortDirection="asc"
                 (matSortChange)="filialiPaginator.sort.next($event)"
                 fxFlex>
        <ng-container matColumnDef="fil_id">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Id</mat-header-cell>
          <mat-cell *matCellDef="let filiale">{{filiale.fil_id}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="fil_nome">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Nome</mat-header-cell>
          <mat-cell *matCellDef="let filiale">{{filiale.fil_nome}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="fil_capoarea">
          <mat-header-cell mat-sort-header *matHeaderCellDef>Capo area</mat-header-cell>
          <mat-cell *matCellDef="let filiale">{{filiale.fil_capoarea}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="fil_descrizione">
          <mat-header-cell *matHeaderCellDef>Descrizione</mat-header-cell>
          <mat-cell *matCellDef="let filiale">{{filiale.fil_descrizione}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="fil_scambio_flussi">
          <mat-header-cell *matHeaderCellDef>Scambio flussi</mat-header-cell>
          <mat-cell *matCellDef="let filiale" class="text-center">
            <mat-checkbox [checked]="filiale.fil_scambio_flussi" #ckscambioflussi (click)="$event.preventDefault();ckscambioflussi.checked=filiale.fil_scambio_flussi"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="fil_puoimportare">
          <mat-header-cell *matHeaderCellDef>Può importare</mat-header-cell>
          <mat-cell *matCellDef="let filiale" class="text-center">
            <mat-checkbox [checked]="filiale.fil_puoimportare"  #ckpuoimportare (click)="$event.preventDefault();ckpuoimportare.checked=filiale.fil_puoimportare"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="fil_cap">
          <mat-header-cell *matHeaderCellDef>CAP</mat-header-cell>
          <mat-cell *matCellDef="let filiale">{{filiale.fil_cap}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="fil_localita">
          <mat-header-cell *matHeaderCellDef>Località</mat-header-cell>
          <mat-cell *matCellDef="let filiale">{{filiale.fil_localita}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="fil_provincia">
          <mat-header-cell *matHeaderCellDef>Provincia</mat-header-cell>
          <mat-cell *matCellDef="let filiale">{{filiale.fil_provincia}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="fil_codcliarca">
          <mat-header-cell *matHeaderCellDef>Cod. cli. Arca</mat-header-cell>
          <mat-cell *matCellDef="let filiale">{{filiale.fil_codcliarca}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="fil_codforarca">
          <mat-header-cell *matHeaderCellDef>Cod. for. Arca</mat-header-cell>
          <mat-cell *matCellDef="let filiale">{{filiale.fil_codforarca}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="fil_attiva">
          <mat-header-cell *matHeaderCellDef>Attiva</mat-header-cell>
          <mat-cell *matCellDef="let filiale" class="text-center">
            <mat-checkbox [checked]="filiale.fil_attiva"  #ckattiva (click)="$event.preventDefault();ckattiva.checked=filiale.fil_attiva"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="fil_non_smistabile">
          <mat-header-cell *matHeaderCellDef>Non smistabile</mat-header-cell>
          <mat-cell *matCellDef="let filiale" class="text-center">
            <mat-checkbox [checked]="filiale.fil_non_smistabile" #cknonsmistabile (click)="$event.preventDefault();cknonsmistabile.checked=filiale.fil_non_smistabile"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="fil_piva">
          <mat-header-cell *matHeaderCellDef>Partita IVA</mat-header-cell>
          <mat-cell *matCellDef="let filiale">{{filiale.fil_piva}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="fil_gestitadasede">
          <mat-header-cell *matHeaderCellDef>Gest. da sede</mat-header-cell>
          <mat-cell *matCellDef="let filiale" class="text-center">
            <mat-checkbox [checked]="filiale.fil_gestitadasede" #ckgestitadasede (click)="$event.preventDefault();ckgestitadasede.checked=filiale.fil_gestitadasede"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="fil_hub_regionale">
          <mat-header-cell *matHeaderCellDef>H. R.</mat-header-cell>
          <mat-cell *matCellDef="let filiale" class="text-center">
            <mat-checkbox [checked]="filiale.fil_hub_regionale" #ckhubreg (click)="$event.preventDefault();ckhubreg.checked=filiale.fil_hub_regionale"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="fil_hub_nazionale">
          <mat-header-cell *matHeaderCellDef>H. N.e</mat-header-cell>
          <mat-cell *matCellDef="let filiale" class="text-center">
            <mat-checkbox [checked]="filiale.fil_hub_nazionale" #ckhubnaz (click)="$event.preventDefault();ckhubnaz.checked=filiale.fil_hub_nazionale"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="fil_gesttariffe">
          <mat-header-cell *matHeaderCellDef>Gest. tariffe</mat-header-cell>
          <mat-cell *matCellDef="let filiale" class="text-center">
            <mat-checkbox [checked]="filiale.fil_gesttariffe"  #ckgesttar (click)="$event.preventDefault();ckgesttar.checked=filiale.fil_gesttariffe"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="fil_vedeesitibarcode">
          <mat-header-cell *matHeaderCellDef>Vede esiti barcode</mat-header-cell>
          <mat-cell *matCellDef="let filiale" class="text-center">
            <mat-checkbox [checked]="filiale.fil_vedeesitibarcode" #ckvedeesitibarc (click)="$event.preventDefault();ckvedeesitibarc.checked=filiale.fil_vedeesitibarcode"></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="fil_euroroyaltyrete">
          <mat-header-cell *matHeaderCellDef>Royalty rete</mat-header-cell>
          <mat-cell *matCellDef="let filiale">{{filiale.fil_euroroyaltyrete}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="fil_modofatturazione">
          <mat-header-cell *matHeaderCellDef>Modo fatt.</mat-header-cell>
          <mat-cell *matCellDef="let filiale">{{filiale.fil_modofatturazione}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="fil_vede_royalty_filiale">
          <mat-header-cell *matHeaderCellDef>Vede royalty</mat-header-cell>
          <mat-cell *matCellDef="let filiale" class="text-center">
            <mat-checkbox [checked]="filiale.fil_vede_royalty_filiale" #ckvederoyfil (click)="$event.preventDefault();ckvederoyfil.checked=filiale.fil_vede_royalty_filiale" ></mat-checkbox>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="updated_at">
          <mat-header-cell *matHeaderCellDef>Ultima modifica</mat-header-cell>
          <mat-cell *matCellDef="let filiale">{{filiale.updated_at | moment}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions" stickyEnd>
          <mat-header-cell *matHeaderCellDef>Azioni</mat-header-cell>
          <mat-cell *matCellDef="let filiale">
            <a mat-icon-button color="accent" href="#" [routerLink]="[filiale.fil_id]">
              <mat-icon>edit</mat-icon>
            </a>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
        <mat-row *matRowDef="let filiale; columns: displayedColumns;"></mat-row>
      </mat-table>

      <div class="h-56 px-64 pb-24" fxLayout="row" fxLayoutAlign="space-between center">
        <span class="mat-caption">@FulmineGroup</span>

        <app-paginator [paginator]="filialiPaginator"></app-paginator>
      </div>
    </div>
    <!-- / CONTENT -->

  </div>
  <!-- / CENTER -->

</div>
