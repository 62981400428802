import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Paginator, Query} from '../../../models/paginator';
import {SelectionModel} from '@angular/cdk/collections';
import {FormControl} from '@angular/forms';
import {BehaviorSubject, Subject} from 'rxjs';
import {debounceTime, filter, takeUntil} from 'rxjs/operators';
import {TableColumn} from '../../../models/table-column';
import {Sort} from '@angular/material/sort';
import {Ditta} from '../../../../sdoc/models/entities/ditta';
import {Regione} from '../../../../sdoc/models/entities/regione';

@Component({
  selector: 'app-multi-entity-selector-dialog',
  templateUrl: './multi-entity-selector-dialog.component.html',
  styleUrls: ['./multi-entity-selector-dialog.component.scss']
})
export class MultiEntitySelectorDialogComponent<T> implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any>;

  public formControl = new FormControl();
  public selection: SelectionModel<T>;

  public readonly displayedColumns = ['ditta_id', 'regione', 'provincia', 'ditta_filiale', 'elenco_capoarea'];

  public ditte: Ditta[];
  public regioni: Regione[];

  private lastQueries: Query[];
  private nonRiselezionare = new BehaviorSubject<boolean>(false);

  constructor(@Inject(MAT_DIALOG_DATA) public data: Payload<T>,
              private _dialogRef: MatDialogRef<MultiEntitySelectorDialogComponent<T>>) {
  }

  ngOnInit(): void {
    this._unsubscribeAll = new Subject();
    this.data.paginator.queries.next(this.data.filters ?? []);
    this.data.paginator.sort.next(this.data.sort);
    this.selection = new SelectionModel<T>(this.data.multiSelect, this.data.selectedEntities ?? []);

    const self = this;
    this.data.paginator.page.pipe(takeUntil(this._unsubscribeAll)).subscribe(page => {
      // TODO: capire perchè dentro qui non riesco a leggere il valore di nonRiselezionare, che da sempre false
      // console.log('non selezionare', self.nonRiselezionare.getValue());
      if (!self.nonRiselezionare.getValue()){
        for (const element of page?.data ?? []) {
          for (const selectedElement of this.selection.selected) {
            if (JSON.stringify(element) === JSON.stringify(selectedElement)) {
              console.log('selezionare', element);
              this.selection.deselect(selectedElement);
              this.selection.select(element);
              break;
            }
          }
        }
      }
    });

    this.formControl.valueChanges.pipe(
      takeUntil(this._unsubscribeAll),
      debounceTime(500),
      filter(value => typeof value === 'string')
    ).subscribe(value => {
      this.lastQueries = [...(this.data.filters ?? []), ...(this.buildQueries(value))];
      this.data.paginator.loadPage(
        0,
        this.lastQueries,
        this.data.sort
      );
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  public async selezionaTutti(checked: boolean): Promise<void> {
    if (checked && this.data.multiSelect) {
      this.nonRiselezionare.next(true);

      this.selection.clear();

      const elementiPrimaPag = [];
      const page = this.data.paginator.page.getValue();
      page.data.forEach(element => {
        this.selection.select(element);
        elementiPrimaPag.push(element);
      });

      const tutti = await this.data.paginator.loadAll(this.lastQueries, this.data.sort, true);
      tutti.forEach(entity => {

        let flag = false;
        for (const selectedElement of elementiPrimaPag) {
          if (JSON.stringify(entity) === JSON.stringify(selectedElement)) {
            flag = true;
            break;
          }
        }
        if (!flag){
          this.selection.select(entity);
        }
      });
      this.nonRiselezionare.next(false);
    }
  }

  private buildQueries(search: string): Query[] {
    const queries = [];
    for (const searchField of this.data.searchFields) {
      queries.push({column: searchField, search: search});
    }
    return queries;
  }

  public onConfirmButtonClicked(): void {
    this._dialogRef.close(this.data.multiSelect ? this.selection.selected : this.selection.selected[0]);
  }
}

export interface Payload<T> {
  columns: TableColumn[];
  displayedColumns: string[];
  filters?: Query[];
  multiSelect: boolean;
  paginator: Paginator<T>;
  searchFields: string[];
  selectedEntities?: T[];
  sort?: Sort;
  showSelectAll?: boolean;
}
